import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Badge,
  IconButton,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import Sidebar from "./sidebar";
import { AddLinkOutlined, Notifications, Delete } from "@mui/icons-material";
import axios from "axios";

const Settings = () => {
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isAdding, setIsAdding] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // // Custom WebSocket hook
  // const { socket } = useWebSocket({
  //   url: "ws://192.168.0.6:8766",
  //   onMessage: (message) => {
  //     if (message === "requestOTP") {
  //       setOtpModalOpen(true);
  //       setNotifications((prev) => [
  //         ...prev,
  //         { message: "OTP requested", seen: false },
  //       ]);
  //       setUnreadNotifications((prev) => prev + 1);
  //     }
  //   },
  // });

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {

    localStorage.setItem('linkedinmail',formData.email)
    
    if (!formData.email || !formData.password) {
      setSnackbarMessage("Please enter both email and password.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.post("http://127.0.0.1:8000/myapp/login/", formData);

      if (response.status === 200) {
        setLinkedAccounts([
          ...linkedAccounts,
          { email: formData.email, status: "Requested" },
        ]);
        setSnackbarMessage("Account linked successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        throw new Error("Failed to add account");
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while adding the account.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  

  return (
    <>
      <Sidebar
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        notificationCount={unreadNotifications}
      />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: sidebarOpen ? "70vw" : "80vw",
        }}
      >
        <Box sx={{ padding: 4, borderRadius: "10px", background: "#ffffff", width: "80%" }}>
          <Box display="flex" justifyContent="space-between" mb={3}>
            <Typography variant="h5" fontWeight={600}>
              LinkedIn Accounts
            </Typography>
            <Badge
              badgeContent={unreadNotifications}
              color="error"
              onClick={() => setNotificationModalOpen(true)}
            >
              <IconButton>
                <Notifications />
              </IconButton>
            </Badge>
          </Box>

          {isAdding ? (
            <>
              <form onSubmit={(e) => e.preventDefault()}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
              <Typography variant="h6" mb={2}>Enter LinkedIn Credentials</Typography>

                    <TextField
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Password"
                      name="password"
                      type="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                  Link Account
                </Button>
              </form>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={() => setIsAdding(true)}
              startIcon={<AddLinkOutlined />}
            >
              Add LinkedIn Account
            </Button>
          )}

          <List>
            {linkedAccounts.map((account, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={account.email}
                  secondary={`Status: ${account.status}`}
                />
                <IconButton onClick={() => setOtpModalOpen(true)}>
                  <Delete />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Container>

     
      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Settings;
