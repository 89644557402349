import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Badge,
  Avatar,
  Box,
  styled,
} from "@mui/material";
import {
  Search as SearchIcon,
  Notifications as NotificationsIcon,
  Help as HelpIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import CampaignIcon from "@mui/icons-material/Campaign";

const useAppColors = () => ({
  primary: "#c1761f",
  secondary: "#4062ad",
  lightBackground: "#ffffff",
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#ffffff",
  zIndex: theme.zIndex.drawer + 1, // Ensures it's above the sidebar
  boxShadow: "none",
  borderBottom: "1px solid #e0e0e0",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
}));

const TopAppBar = ({ notificationCount }) => {
  const colors = useAppColors();

  return (
    <StyledAppBar>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
        }}
      >
        {/* Left Section: Logo and Title */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CampaignIcon
            sx={{ color: colors.primary, fontSize: "32px", marginRight: "8px" }}
          />
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#000" }}>
            Campaigns
          </Typography>
        </Box>

        {/* Middle Section: Trial Info */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#fff8e1",
            padding: "4px 12px",
            borderRadius: "20px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "#f57c00", marginRight: "8px", fontWeight: 600 }}
          >
            Trial expires in 13 days
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.primary,
              color: "#fff",
              textTransform: "none",
              padding: "4px 16px",
              fontSize: "14px",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#a86518",
              },
            }}
          >
            Upgrade
          </Button>
        </Box>

        {/* Right Section: Icons and Profile */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <IconButton>
            <SearchIcon sx={{ color: "#757575" }} />
          </IconButton>
          <IconButton>
            <Badge badgeContent={notificationCount} color="error">
              <NotificationsIcon sx={{ color: "#757575" }} />
            </Badge>
          </IconButton>
          <IconButton>
            <HelpIcon sx={{ color: "#757575" }} />
          </IconButton>
          <IconButton>
            <SettingsIcon sx={{ color: "#757575" }} />
          </IconButton>
          <Avatar
            alt="Profile Picture"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 32, height: 32 }}
          />
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default TopAppBar;
