import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Stack,
  Container,
  useTheme,
} from "@mui/material";
import { Visibility, TrendingUp } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import AddIcon from "@mui/icons-material/Add";
import Sidebar from "./sidebar.js";

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // Initial state for sidebar
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };
  const handleCampaignsClick = () => {
    navigate("/createcampaign");
  };
  const handleView = () => {
    navigate("/leads");
  };

  const handleLeadsClick = () => {
    navigate("/newlead");
  };

  const handleContentClick = () => {
    navigate("/promptgen");
  };
  return (
    <div>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <Grid
        sx={{
          paddingX: 2,
          width: sidebarOpen
            ? { xs: "0", sm: "80%" }
            : { xs: "100%", sm: "90%" },
          ml: sidebarOpen ? { xs: "0", sm: "230px" } : { xs: "0", sm: "90px" },
        }}
      >
        <Box sx={{ padding: "20px", backgroundColor: "#FFF" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", fontSize: "36px" }}
              >
                Overview
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "gray", fontSize: "20px", color: "#1E0508" }}
              >
                Marketing Campaigns
              </Typography>
            </Box>
          </Box>

          {/* Performance Overview */}
          <Typography
            variant="body1"
            sx={{
              marginBottom: "20px",
              fontWeight: "800px",
              color: "gray",
              fontSize: "24px",
              color: "#00BD82",
              marginLeft: "20px",
            }}
          >
            Set Up Targeted Lead Campaign
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Card
              sx={{
                width: "30%",
                height: "200px",
                marginRight: 2,
                borderRadius: "24px",
                background:
                  '#F8E6E0 url("Assets/images/campaigns.svg") no-repeat right bottom',
                backgroundSize: "cover",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Create Campaign
                  </Typography>
                  <Typography variant="body1" sx={{ color: "gray" }}>
                    Create Your Campaigns
                  </Typography>
                </Box>
                {/* Add chart or statistic here */}
                <Button
                  onClick={handleCampaignsClick}
                  sx={{
                    marginTop: 2,
                    width: "fit-content",
                    padding: "4px",
                    textTransform: "none",
                    fontSize: "18px",
                    color: "#FFF",
                    borderRadius: "16px", // Button border radius

                    display: "flex", // Centers the content
                    alignItems: "center",
                    justifyContent: "center",
                    justifySelf: "flex-end",
                    paddingX: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      borderRadius: "50%",
                      backgroundColor: "#A8C0F0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "rgba(58, 89, 183, 0.9)", // Icon color
                    }}
                  >
                    <AddIcon sx={{ width: 30, height: 30 }} />
                  </Box>
                </Button>
              </CardContent>
            </Card>
            <Card
              sx={{
                width: "30%",
                height: "200px",
                marginRight: 2,
                borderRadius: "24px",
                background:
                  '#F7F7F5 url("Assets/images/leads.svg") no-repeat left bottom',
                backgroundSize: "cover",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Create Leads
                  </Typography>
                  <Typography variant="body1" sx={{ color: "gray" }}>
                    Create Your Leads
                  </Typography>
                </Box>
                {/* Add chart or statistic here */}
                <Button
                  onClick={handleLeadsClick}
                  sx={{
                    marginTop: 2,
                    width: "fit-content",
                    padding: "4px",
                    textTransform: "none",
                    fontSize: "18px",
                    color: "#FFF",
                    borderRadius: "16px", // Button border radius

                    display: "flex", // Centers the content
                    alignItems: "center",
                    justifyContent: "center",
                    justifySelf: "flex-end",
                    paddingX: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      borderRadius: "50%",
                      backgroundColor: "#A8C0F0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "rgba(58, 89, 183, 0.9)", // Icon color
                    }}
                  >
                    <AddIcon sx={{ width: 30, height: 30 }} />
                  </Box>
                </Button>
              </CardContent>
            </Card>
            <Card
              sx={{
                width: "30%",
                height: "200px",
                backgroundColor: "#F9EFE0",
                borderRadius: "24px",
                background:
                  '#F7F7F5 url("Assets/images/generatecontent.svg") no-repeat left bottom',
                backgroundSize: "cover",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Create Content
                  </Typography>
                  <Typography variant="body1" sx={{ color: "gray" }}>
                    Create Your Campaign Content
                  </Typography>
                </Box>
                {/* Add chart or statistic here */}
                <Button
                  onClick={handleContentClick}
                  disabled
                  sx={{
                    marginTop: 2,
                    width: "fit-content",
                    padding: "4px",
                    textTransform: "none",
                    fontSize: "18px",
                    color: "#FFF",
                    borderRadius: "16px", // Button border radius

                    display: "flex", // Centers the content
                    alignItems: "center",
                    justifyContent: "center",
                    justifySelf: "flex-end",
                    paddingX: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      borderRadius: "50%",
                      backgroundColor: "#D5D5D5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "rgba(58, 89, 183, 0.9)", // Icon color
                    }}
                  >
                    <AddIcon sx={{ width: 30, height: 30 }} />
                  </Box>
                </Button>
              </CardContent>
            </Card>
          </Box>

          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              justifySelf: "center",
            }}
          >
            <img
              src="Assets/images/logoplugleads.svg"
              alt="Logo"
              style={{ width: "50%", opacity: "0.4" }}
            />
          </Box>
        </Box>
      </Grid>
    </div>
  );
};

export default Dashboard;
