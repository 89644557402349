import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  Grid,
  FormControl,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  TextField,
} from "@mui/material";
import Sidebar from "./sidebar";

const TenantUser = () => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    userTypes: [],
  });
  const [errors, setErrors] = useState({});
  const API_URL = "https://management.plugleads.in/users/users";

  // Fetch users when the component mounts
  useEffect(() => {
    fetchUsers();
  }, []);

  // Fetch all users
  const fetchUsers = async () => {
    try {
      // Retrieve access token from localStorage
      let token = localStorage.getItem('access_token');
      let refreshToken = localStorage.getItem('refresh_token');
  
      // If no access token is available or it's invalid, try refreshing it
      if (!token && refreshToken) {
        const refreshResponse = await axios.post(
          `https://management.plugleads.in/users/token/refresh/`,  // Ensure the correct URL for your refresh token endpoint
          { refresh_token: refreshToken }  // Send refresh token in request body
        );
  
        token = refreshResponse.data.access_token;
        localStorage.setItem('access_token', token);  // Store new access token
      }
  
      if (!token) {
        setSnackbar({
          open: true,
          message: "Authorization failed. Please log in again.",
          severity: "error",
        });
        return;
      }
  
      // Now that we have a valid token, make the API call to fetch users
      const response = await axios.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${token}`,  // Use Bearer token for authorization
        },
      });
  
      setUsers(response.data);  // Assuming the API returns a list of users
    } catch (error) {
      console.error("Error fetching users:", error);
  
      // If the error is due to invalid token, try refreshing the token
      if (error.response?.data?.detail === "Given token not valid for any token type") {
        try {
          // Try to refresh the token if access token is expired or invalid
          const refreshResponse = await axios.post(
            `https://management.plugleads.in/users/token/refresh/`,
            { refresh_token: refreshToken }
          );
  
          // Update the access token in localStorage and retry fetching users
          const newToken = refreshResponse.data.access_token;
          localStorage.setItem('access_token', newToken);
  
          // Retry the request with the new access token
          const retryResponse = await axios.get(`${API_URL}/`, {
            headers: {
              Authorization: `Bearer ${newToken}`,
            },
          });
  
          setUsers(retryResponse.data);  // Update user list
        } catch (refreshError) {
          setSnackbar({
            open: true,
            message: "Failed to refresh token. Please log in again.",
            severity: "error",
          });
        }
      } else {
        setSnackbar({
          open: true,
          message: "Failed to fetch users. Please try again.",
          severity: "error",
        });
      }
    }
  };
  
  

  // Handle input change and clear errors when typing
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  // Handle user type selection
  const handleUserTypeChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, userTypes: value }));
    setErrors((prev) => ({ ...prev, userTypes: "" }));
  };

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};
    if (!formData.username) newErrors.username = "Username is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    if (formData.userTypes.length === 0)
      newErrors.userTypes = "At least one user type is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleAddUser = async () => {
    if (!validateForm()) {
      setSnackbar({
        open: true,
        message: "Please fill out all required fields.",
        severity: "error",
      });
      return;
    }
  
    const payload = new FormData();
    payload.append("username", formData.username);
    payload.append("email", formData.email);
    payload.append("password", formData.password);
  
    // Append user types as a single field in FormData
    formData.userTypes.forEach((userType, index) => {
      payload.append(`user_types[${index}]`, userType); // Explicitly indexing the user_types array
    });
  
    try {
      let token = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
  
      // If access token is not available, attempt to refresh it using the refresh token
      if (!token && refreshToken) {
        const refreshResponse = await axios.post(`https://management.plugleads.in/users/token/refresh/`, {
          refresh: refreshToken,
        });
  
        const newAccessToken = refreshResponse.data.access_token;
        localStorage.setItem('access_token', newAccessToken); // Update the access token in localStorage
        token = newAccessToken; // Use the new access token
      }
  
      if (!token) {
        setSnackbar({
          open: true,
          message: "Authorization failed. Please log in again.",
          severity: "error",
        });
        return;
      }
  
      // Include token in the Authorization header
      await axios.post(
        `${API_URL}/create_user/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach token as Bearer token
          },
        }
      );
  
      // Clear form and show success message
      setFormData({ username: "", email: "", password: "", userTypes: [] });
      setSnackbar({
        open: true,
        message: "User added successfully!",
        severity: "success",
      });
  
      fetchUsers(); // Refresh user list
    } catch (error) {
      console.error("Error adding user:", error.response?.data || error);
  
      const errorMessage =
        error.response?.data?.detail || "Failed to create user. Please try again.";
  
      // Check if error is token-related (invalid or expired token)
      if (
        error.response?.data?.code === "token_not_valid" &&
        error.response?.data?.messages?.some(
          (msg) => msg.token_class === "AccessToken" && msg.token_type === "access"
        )
      ) {
        // Token is invalid or expired, attempt to refresh the token
        const refreshToken = localStorage.getItem('refresh_token');
        console.log(refreshToken,'HHSHSH');
  
        if (refreshToken) {
          try {
            const refreshResponse = await axios.post(`https://management.plugleads.in/users/token/refresh/`, {
              refresh: refreshToken,
            });
  
            const newAccessToken = refreshResponse.data.access;
            localStorage.setItem('access_token', newAccessToken); // Update the access token in localStorage
  
            // Retry the original request with the new access token
            await axios.post(
              `${API_URL}/create_user/`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${newAccessToken}`, // Use the new token
                },
              }
            );
  
            setFormData({ username: "", email: "", password: "", userTypes: [] });
            setSnackbar({
              open: true,
              message: "User added successfully!",
              severity: "success",
            });
  
            fetchUsers(); // Refresh user list
          } catch (refreshError) {
            console.error("Error refreshing token:", refreshError);
            setSnackbar({
              open: true,
              message: "Failed to refresh the token. Please log in again.",
              severity: "error",
            });
          }
        } else {
          setSnackbar({
            open: true,
            message: "No refresh token available. Please log in again.",
            severity: "error",
          });
        }
      } else {
        setSnackbar({
          open: true,
          message: errorMessage,
          severity: "error",
        });
      }
    }
  };
  
  
  
  

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <Box sx={{backgroundColor:'#C1D0F1',}}>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <Box
  sx={{
    width: sidebarOpen
      ? { xs: "0", sm: "80%" }
      : { xs: "100%", sm: "90%" },
    ml: sidebarOpen ? { xs: "0", sm: "20px" } : { xs: "0", sm: "90px" },
  }}
>
  <Box sx={{ padding: "20px", margin: "auto" }}>
  <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>

    <Grid container spacing={4} sx={{backgroundColor:'#C1D0F1',padding:'20px'}}>
      {/* Left Side: User Adding Form */}
      <Grid item xs={12} sm={6} sx={{backgroundColor:'#f2f3f4',padding:'20px',display:'flex',flexDirection:'column',borderRadius:'20px',border:'1px solid #222'}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: "center", width: "100%" }}>
            Add New User
          </Typography>
          <label htmlFor="username" style={{padding:'0',marginBottom:'2px'}}>User Name</label>
          <TextField
            id="username"
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            fullWidth
            error={!!errors.username}
            helperText={errors.username}
            sx={{ marginBottom: "16px" }}
          />
          <label htmlFor="email" style={{padding:'0',marginBottom:'2px'}}>Email</label>
          <TextField
            id="email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
            error={!!errors.email}
            helperText={errors.email}
            sx={{ marginBottom: "16px" }}
          />
          <label htmlFor="password" style={{padding:'0',marginBottom:'2px'}}>Password</label>
          <TextField
            id="password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            fullWidth
            error={!!errors.password}
            helperText={errors.password}
            sx={{ marginBottom: "16px" }}
          />
          <FormControl
            id="usertype"
            error={!!errors.userTypes}
            sx={{ width: "100%", marginBottom: "30px" }}
          >
            <label htmlFor="userTypes" style={{padding:'0',marginBottom:'2px'}}>User Type</label>
            <Select
  id="userTypes"
  multiple
  value={formData.userTypes}
  sx={{ width: "100%" }}
  onChange={handleUserTypeChange}
  renderValue={(selected) => selected.join(", ")}
>
  {["admin", "manager", "regular", "restricted"].map((type) => (
    <MenuItem
      key={type}
      value={type}
      sx={{
        border: formData.userTypes.includes(type)
          ? "2px solid #3f51b5"
          : "1px solid transparent",
        backgroundColor: formData.userTypes.includes(type)
          ? "#f5f5f5"
          : "transparent",
        "&:hover": {
          backgroundColor: "#e0f7fa",
        },
        "&.Mui-selected": {
          backgroundColor: "#c5cae9 !important",
          borderColor: "#3f51b5",
        },
      }}
    >
      {type.charAt(0).toUpperCase() + type.slice(1)}
    </MenuItem>
  ))}
</Select>

            {errors.userTypes && (
              <Typography color="error" variant="caption">
                {errors.userTypes}
              </Typography>
            )}
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUser}
            sx={{ mt: 2, width: "100%" }}
          >
            Add User
          </Button>
        </Box>
      </Grid>

      {/* Right Side: User List */}
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography variant="h4" gutterBottom>
            User List
          </Typography>
          <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Username</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>User Types</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {users.map((user) => (
        <TableRow key={user.user.id}>
          <TableCell>{user.user.username}</TableCell>
          <TableCell>{user.user.email}</TableCell>
          <TableCell>
            {user.user_types.map((type) => type.name).join(", ")}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

        </Box>
      </Grid>
    </Grid>
  </Box>
</Box>

    </Box>
  );
};

export default TenantUser;
