import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Card,
  CardContent,
  Avatar,
  Grid,
  useTheme,
  Paper,
  useMediaQuery,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import LogoutIcon from "@mui/icons-material/Logout";
import StarIcon from "@mui/icons-material/Star";

const LandingPage = () => {
  const theme = useTheme();
  const [isMarketingExpanded, setIsMarketingExpanded] = useState(false);
  const [isExperimentsExpanded, setIsExperimentsExpanded] = useState(false);

  const toggleMarketing = () => {
    setIsMarketingExpanded(!isMarketingExpanded);
  };

  const toggleExperiments = () => {
    setIsExperimentsExpanded(!isExperimentsExpanded);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ backgroundColor: "#FDF9F1" }}>
      <Box
        sx={{
          backgroundColor: "#141414",
          minHeight: "fit-content",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Hero Section */}
        <Box
          sx={{
            textAlign: "center",
            padding: "40px 16px",
            maxWidth: "900px",
            backgroundColor: "#141414",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              lineHeight: 1.5,
              background: "linear-gradient(145deg, #E6FF4B, #00BD82)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text", // For non-webkit browsers
              color: "transparent",
            }}
          >
            Elevate your marketing with unified Email and SMS Marketing solution
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "16px",
              width: "80%",
              justifySelf: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#00BD82",
                color: "#FFF",
                fontWeight: "normal",
                textTransform: "none",
                "&:hover": { backgroundColor: "#00BD82" },
                marginRight: "8px",
                width: "30%",
              }}
            >
              Get Started
            </Button>
            <Button
              variant="text"
              sx={{
                color: "#fff",
                fontWeight: "normal",
                width: "30%",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              How it works
              <PlayArrowIcon
                sx={{
                  marginLeft: "4px",
                  borderRadius: "50%",
                  border: "1px solid #00BD82",
                  padding: "5px",
                  color: "#E2F471",
                }}
              />
            </Button>
          </Box>
          <Box>
            <img
              src="Assets/images/Bargraphs_landing.svg"
              height={"500px"}
              width={"500px"}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#E2F471",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "normal",
              color: "black",
            }}
          >
            1,700+
          </Typography>
          <Typography
            variant="body"
            sx={{
              fontWeight: "normal",
              color: "black",
              fontSize: "12px",
            }}
          >
            Companies using Sendlance
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "normal",

              color: "black",
            }}
          >
            20.000+
          </Typography>
          <Typography
            variant="body"
            sx={{
              fontWeight: "normal",
              color: "black",
              fontSize: "12px",
            }}
          >
            Users Sendlance
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "normal",
              color: "black",
            }}
          >
            40 million+
          </Typography>
          <Typography
            variant="body"
            sx={{
              fontWeight: "normal",
              color: "black",
              fontSize: "12px",
            }}
          >
            Emails sent daily
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          justifySelf: "center",
          mt: 6,
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          gutterBottom
          textAlign={"center"}
          sx={{ marginBottom: "5px" }}
        >
          More than just drips and newsletters
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ opacity: 0.7, textAlign: "center" }}
          gutterBottom
        >
          Awareness through email became a must-have, and our ESP wasn't cutting
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FDF9F1",
          padding: "20px 30px",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Left Content */}

        <Box sx={{ flex: 1, maxWidth: "50%", color: "black" }}>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ marginTop: "0px", marginBottom: "20px", width: "50%" }}
          >
            Drive 30–40% of your revenue with email marketing
          </Typography>
          <Typography
            variant="body1"
            sx={{ opacity: 0.7, marginBottom: "30px", width: "80%" }}
          >
            Unlock customer insights to grow your business faster. Store all
            your customer data collected from storefront to marketing channels
            in one central hub for easy use in every strategy.
          </Typography>
          {/* Features */}
          <Grid container spacing={2}>
            {/* Marketing Automation Section */}
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                  gap: "10px",
                }}
              >
                <Avatar
                  sx={{
                    backgroundColor: "green",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  🏠
                </Avatar>
                <Box>
                  <Typography variant="h6">Marketing Automation</Typography>
                  <Typography variant="body2" sx={{ opacity: 0.7 }}>
                    From open rates to conversion performance, get access to
                    tons of data.
                    {isMarketingExpanded && (
                      <>
                        {" "}
                        This feature helps you improve email campaigns, track
                        customer journeys, and automate marketing tasks for
                        better engagement.
                      </>
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    onClick={toggleMarketing}
                    sx={{
                      color: "green",
                      marginTop: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {isMarketingExpanded ? "Read Less" : "Read More"}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Experiments Section */}
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                  gap: "10px",
                }}
              >
                <Avatar
                  sx={{
                    backgroundColor: "green",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  🧪
                </Avatar>
                <Box>
                  <Typography variant="h6">Experiments</Typography>
                  <Typography variant="body2" sx={{ opacity: 0.7 }}>
                    From sign-up to sale, track your contacts' every move.
                    {isExperimentsExpanded && (
                      <>
                        {" "}
                        Leverage A/B testing to optimize workflows, analyze user
                        behavior, and enhance overall customer engagement
                        strategies.
                      </>
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    onClick={toggleExperiments}
                    sx={{
                      color: "green",
                      marginTop: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {isExperimentsExpanded ? "Read Less" : "Read More"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Right Content */}
        <Box
          sx={{
            flex: 1,
            maxWidth: "40%",
            position: "relative",
            padding: "20px",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              backgroundColor: "#F5F0E5",
              borderRadius: "20px",
              padding: "20px",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Avatar src="/profile.jpg" />
            </Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              color="green"
              sx={{ marginTop: "10px" }}
            >
              Hi Shown.
            </Typography>
            <Typography variant="body1" sx={{ marginTop: "10px" }}>
              We've added a 100% boost to the products in your...
            </Typography>
            <img
              src="Assets/images/landingsvg.svg"
              alt="Placeholder"
              style={{
                width: "100%",
                marginTop: "20px",
                borderRadius: "10px",
              }}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
