import React, { useEffect, useState } from "react";
import { Box, Typography, Snackbar, Button, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import { useNotification } from "./NotificationContext";

const Settings = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate for navigation
  const { addNotification } = useNotification();

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  // Mark notification as read
  const markAsRead = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id ? { ...notification, seen: true } : notification
      )
    );
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: "100vw",
          background: "#f9f9f9",
        }}
      >
        <Box
          sx={{
            padding: 4,
            borderRadius: "10px",
            background: "#ffffff",
            width: "80%",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              marginBottom: 4,
              textAlign: "center",
              color: "#333",
            }}
          >
            Settings
          </Typography>

          {/* Buttons to navigate to components */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              marginBottom: 4,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => navigate("/link")} // Navigate to LinkedIn Linking
            >
              LinkedIn Linking
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate("/tenantuser")} // Navigate to Subscription
            >
              Add user
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate("/license")} // Navigate to Subscription
            >
              Licence Management
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar
        open={notifications.some((notification) => !notification.seen)}
        autoHideDuration={6000}
        onClose={() => markAsRead(notifications[0]?.id)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {notifications.map(
          (notification) =>
            !notification.seen && (
              <Alert
                key={notification.id}
                onClose={() => markAsRead(notification.id)}
                severity="info"
              >
                {notification.message}
              </Alert>
            )
        )}
      </Snackbar>
    </>
  );
};

export default Settings;
