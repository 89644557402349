import React, { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Button,
  Divider,
  TextField,
  Card,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FlagIcon from "@mui/icons-material/Flag";
import Sidebar from "./sidebar";

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [fullName, setFullName] = useState("Saketh peri");
  const [displayName, setDisplayName] = useState("Saketh peri");
  const [gender, setGender] = useState("Prefer not to say");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    // Save logic here
    setIsEditing(false);
  };

  return (
    <Box>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <Box
        sx={{
          width: sidebarOpen ? "70%" : "90%",
          ml: sidebarOpen ? { xs: "0", sm: "240px" } : { xs: "0", sm: "90px" },
          p: 4,
        }}
      >
        {/* Profile Information */}
        <Card sx={{ p: 3, mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={10}>
              <Box display="flex" alignItems="center">
                <Avatar
                  src="https://via.placeholder.com/80"
                  sx={{ width: 80, height: 80, mr: 2 }}
                />
                <Box>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Saketh peri
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    saketh.peri.nexii@gmail.com
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={2} textAlign="right">
              <Button
                variant="contained"
                color={isEditing ? "primary" : "success"}
                startIcon={isEditing ? null : <EditIcon />}
                onClick={isEditing ? handleSave : handleEditToggle}
              >
                {isEditing ? "Save" : "Edit"}
              </Button>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Full Name
              </Typography>
              {isEditing ? (
                <TextField
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  fullWidth
                  size="small"
                />
              ) : (
                <Typography variant="body1">{fullName}</Typography>
              )}
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Display Name
              </Typography>
              {isEditing ? (
                <TextField
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  fullWidth
                  size="small"
                />
              ) : (
                <Typography variant="body1">{displayName}</Typography>
              )}
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Gender
              </Typography>
              {isEditing ? (
                <Select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  fullWidth
                  size="small"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Prefer not to say">
                    Prefer not to say
                  </MenuItem>
                </Select>
              ) : (
                <Typography variant="body1">{gender}</Typography>
              )}
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Country/Region
              </Typography>
              <Typography variant="body1">
                <FlagIcon
                  sx={{ fontSize: 20, verticalAlign: "middle", mr: 1 }}
                />
                India
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                State
              </Typography>
              <Typography variant="body1">Andhra Pradesh</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                Language
              </Typography>
              <Typography variant="body1">English</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Time zone
              </Typography>
              <Typography variant="body1">
                (GMT +05:30) India Standard Time (Asia/Kolkata)
              </Typography>
            </Grid>
          </Grid>
        </Card>

        {/* Email Addresses Section */}
        <Card sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            My Email Addresses
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            You can use the following email addresses to sign in to your account
            and also to reset your password if you ever forget it.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <EmailIcon color="error" sx={{ mr: 2, fontSize: 30 }} />
              <Box>
                <Typography variant="body1">
                  saketh.peri.nexii@gmail.com
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  1 day ago
                </Typography>
              </Box>
            </Box>
            <Box>{/* Add Google, Apple icons here */}</Box>
          </Box>
          <Button
            startIcon={<AddIcon />}
            sx={{ mt: 2 }}
            size="small"
            color="primary"
          >
            Add Email Address
          </Button>
        </Card>

        {/* Mobile Numbers Section */}
        <Card sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            My Mobile Numbers
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            View and manage all of the mobile numbers associated with your
            account.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <PhoneIcon color="secondary" sx={{ mr: 2, fontSize: 30 }} />
              <Box>
                <Typography variant="body1">+91 93807 55502</Typography>
                <Typography variant="body2" color="textSecondary">
                  1 day ago
                </Typography>
              </Box>
            </Box>
            <Box>{/* Add SIM provider icons here */}</Box>
          </Box>
          <Button
            startIcon={<AddIcon />}
            sx={{ mt: 2 }}
            size="small"
            color="primary"
          >
            Add Mobile Number
          </Button>
        </Card>
      </Box>
    </Box>
  );
};

export default Profile;
