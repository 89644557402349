import React, { createContext, useState, useEffect } from "react";

export const WebSocketContext = createContext();

const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [otpTriggered, setOtpTriggered] = useState(false);

  useEffect(() => {
    const ws = new WebSocket("wss://172.31.36.70:8765"); // Replace with your WebSocket URL

    ws.onmessage = (event) => {
      let data;
      try {
        // Try parsing the incoming data as JSON
        data = JSON.parse(event.data);
        console.log("WebSocket Message:", data);

        // Handle valid JSON messages
        if (data.type === "otp") {
          setOtpTriggered(true); // Trigger OTP modal if "type" is "otp"
        }
      } catch (error) {
        // If it's not valid JSON, handle it as a raw string
        console.error("Received non-JSON message:", event.data);
        // If the message is "requestOTP", trigger the OTP modal
        if (event.data === "requestOTP") {
          setOtpTriggered(true); // Open OTP modal
        }
      }
    };

    ws.onclose = () => {
      console.log("WebSocket Disconnected");
      setSocket(null);
    };

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, []);

  return (
    <WebSocketContext.Provider
      value={{ socket, otpTriggered, setOtpTriggered }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
