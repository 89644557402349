import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Card, CardContent, Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Sidebar from "./sidebar";

const UserLicensesManagement = () => {
   const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const navigate = useNavigate(); // Initialize useNavigate for navigation
  
    const toggleSidebar = () => {
      setSidebarOpen((prev) => !prev);
    };
  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
    <Box sx={{ marginTop: 4, width: sidebarOpen?'75%': '80%',justifySelf:'center', marginLeft: sidebarOpen?'250px': '80px' }}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Choose Your Subscription Plan
      </Typography>
      <Typography variant="body1" sx={{ color: "gray", marginBottom: 2 }}>
        Get access to exclusive features with our subscription plans
      </Typography>

      <Grid container spacing={3} sx={{ marginTop: 2 }}>
        {/* Card 1 - Basic Plan */}
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              backgroundImage: "linear-gradient(to bottom right, #E59851, #F1815B)",
              borderRadius: "16px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              height: "300px", // Reduced height
              padding: 2,
            }}
          >
            <Button disableRipple disableFocusRipple sx={{ textTransform: "none", padding: 0, "&:hover": { backgroundColor: "transparent" } }}>
              <Card
                sx={{
                  position: "relative",
                  zIndex: 2,
                  textAlign: "left",
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "16px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #FFF",
                  padding: "8px",
                  display: "flex",
                  height: "260px", // Reduced height
                  flexDirection: "column",
                  justifyContent: "space-between",
                  opacity: "0.8",
                  color: "#000",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="Assets/images/Crown.svg"
                      alt="Crown_"
                      width={"25px"}
                      height={"25px"}
                      style={{ marginRight: "8px" }}
                    />
                    Basic Plan
                  </Typography>
                  <Typography variant="h6" sx={{ marginTop: 1 }}>
                    $9.99 / Month
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#000", marginTop: 1 }}>
                    Perfect for individuals just starting out.
                  </Typography>
                  <Grid container spacing={1} sx={{ marginTop: 2 }}>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "#000", marginRight: 1 }} />
                        3 Projects
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "#000", marginRight: 1 }} />
                        Email Support
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "#000", marginRight: 1 }} />
                        Basic Analytics
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Button>
          </Box>
        </Grid>

        {/* Card 2 - Premium Plan */}
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              backgroundImage: "linear-gradient(to bottom right, #00c6ff, #0072ff)",
              borderRadius: "16px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              height: "300px", // Reduced height
              padding: 2,
            }}
          >
            <Button disableRipple disableFocusRipple sx={{ textTransform: "none", padding: 0, "&:hover": { backgroundColor: "transparent" } }}>
              <Card
                sx={{
                  position: "relative",
                  zIndex: 2,
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "16px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #FFF",
                  padding: "8px",
                  display: "flex",
                  textAlign: "left",
                  height: "260px", // Reduced height
                  flexDirection: "column",
                  justifyContent: "space-between",
                  opacity: "0.8",
                  color: "#000",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="Assets/images/Crown.svg"
                      alt="Crown_"
                      width={"25px"}
                      height={"25px"}
                      style={{ marginRight: "8px" }}
                    />
                    Premium Plan
                  </Typography>
                  <Typography variant="h6" sx={{ marginTop: 1 }}>
                    $29.99 / Month
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#000", marginTop: 1 }}>
                    Ideal for small teams and growing businesses.
                  </Typography>
                  <Grid container spacing={1} sx={{ marginTop: 2 }}>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "#000", marginRight: 1 }} />
                        10 Projects
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "#000", marginRight: 1 }} />
                        Priority Support
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "#000", marginRight: 1 }} />
                        Advanced Analytics
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Button>
          </Box>
        </Grid>

        {/* Card 3 - Elite Plan */}
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              backgroundImage: "linear-gradient(to bottom right, #f79c42, #f2dc77)",
              borderRadius: "16px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              height: "300px", // Reduced height
              padding: 2,
            }}
          >
            <Button disableRipple disableFocusRipple sx={{ textTransform: "none", padding: 0, "&:hover": { backgroundColor: "transparent" } }}>
              <Card
                sx={{
                  position: "relative",
                  zIndex: 2,
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "16px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #FFF",
                  padding: "8px",
                  display: "flex",
                  textAlign: "left",
                  height: "260px", // Reduced height
                  flexDirection: "column",
                  justifyContent: "space-between",
                  opacity: "0.8",
                  color: "#000",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="Assets/images/Crown.svg"
                      alt="Crown_"
                      width={"25px"}
                      height={"25px"}
                      style={{ marginRight: "8px" }}
                    />
                    Elite Plan
                  </Typography>
                  <Typography variant="h6" sx={{ marginTop: 1 }}>
                    $49.99 / Month
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#000", marginTop: 1 }}>
                    Best for large teams and enterprises.
                  </Typography>
                  <Grid container spacing={1} sx={{ marginTop: 2 }}>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "#000", marginRight: 1 }} />
                        Unlimited Projects
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "#000", marginRight: 1 }} />
                        24/7 Support
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "#000", marginRight: 1 }} />
                        Enterprise Solutions
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default UserLicensesManagement;
