import React, { useContext, useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import axios from 'axios';  // Add axios import
import { WebSocketContext } from './webSocketcontext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const OTPModal = () => {
  const { otpTriggered, setOtpTriggered } = useContext(WebSocketContext); // Context for OTP triggered state
  const [otp, setOtp] = useState('');  // State to hold OTP value

  // Snackbar state and functions (for error/success feedback)
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Assuming selectedAccount is passed via props or context
  const selectedAccount = localStorage.getItem('linkedinmail'); // For demonstration, replace it with dynamic account data

  const handleClose = () => setOtpTriggered(false);  // Close OTP modal

  const handleOtpVerification = async () => {
    try {
      const response = await axios.post(
        "http://127.0.0.1:8000/myapp/otp/store_otp/", // Replace with actual API URL
        { email: selectedAccount, otp }  // Send OTP and email data
      );

      if (response.status === 200) {
        setSnackbarMessage("OTP verified successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        handleClose();  // Close the OTP modal after success
        setOtp("");  // Clear OTP field
      } else {
        throw new Error("OTP verification failed");
      }
    } catch (error) {
      setSnackbarMessage("An error occurred during OTP verification.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };



  useEffect(() => {
    if (otpTriggered) {
      console.log('OTP Modal Opened');
    }
  }, [otpTriggered]);

  return (
    <Modal
      open={otpTriggered}
      onClose={handleClose}
      aria-labelledby="otp-modal-title"
      aria-describedby="otp-modal-description"
    >
      <Box sx={style}>
        <Typography id="otp-modal-title" variant="h6" component="h2">
          Enter OTP
        </Typography>
        <Typography id="otp-modal-description" sx={{ mt: 2 }}>
          Please enter the OTP sent to your registered email or phone number.
        </Typography>
        <input
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          placeholder="Enter OTP"
          style={{ marginTop: '20px', padding: '8px', width: '100%' }}
        />
        <div style={{ marginTop: '20px', textAlign: 'right' }}>
          <Button variant="contained" onClick={handleOtpVerification} sx={{ mr: 2 }}>
            Submit
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default OTPModal;
