import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Button,
  IconButton,
  Dialog,
  InputAdornment,
  Snackbar,
  DialogActions,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";

const SignIn = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    two_factor_token: "",
  });
  const [isFirstTime, setIsFirstTime] = useState(false); // Track if it's first time login
  const navigate = useNavigate();
  const [isTokenRequired, setIsTokenRequired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    two_factor_token: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [open, setOpen] = useState(false); // For controlling the modal visibility
  const [qrCode, setQrCode] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Reset error state when user starts editing the field
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleFirstTimeToggle = (event) => {
    setIsFirstTime(event.target.checked); // Update the first time login state
  };

  const handleClose = () => {
    if (reason === "clickaway") {
      return; // Ignore click-away actions
    }
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Construct the payload with or without the two_factor_token
      const payload = {
        username: formData.username,
        password: formData.password,
      };

      // Include two_factor_token if it exists
      if (formData.two_factor_token) {
        payload.two_factor_token = formData.two_factor_token;
      }

      // Make the login API call and store the response
      const response = await axios.post(
        `https://management.plugleads.in/users/auth/login/`,
        payload
      );

      // Check if response exists and has data
      if (response && response.data) {
        const { access, refresh, user } = response.data;
        const { is_tenant_admin } = user;
        // Store tokens in localStorage
        localStorage.setItem("access_token", access);
        localStorage.setItem("refresh_token", refresh);
        localStorage.setItem("admin", is_tenant_admin);

        // Successful login, handle accordingly
        setSnackbarMessage("Login successful!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        // Navigate to the dashboard
        navigate("/dashboard");
      } else {
        throw new Error("Invalid response from the server.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data.requires_2fa_setup !== undefined
      ) {
        const { requires_2fa_setup } = error.response.data; // Accessing directly from data

        console.log("requires_2fa_setup:", requires_2fa_setup);

        if (requires_2fa_setup) {
          // Call the 2FA enable API to get the QR code
          const authHeader =
            "Basic " + btoa(`${formData.username}:${formData.password}`);
          const enable2faResponse = await axios.post(
            "https://management.plugleads.in/users/2fa/enable/",
            { password: formData.password },
            { headers: { Authorization: authHeader } }
          );

          const qrCode = enable2faResponse.data.qr_code;
          if (qrCode) {
            setQrCode(qrCode); // Set QR code in state
            setOpen(true); // Open the dialog to display QR code
          } else {
            setSnackbarMessage("Failed to retrieve QR code. Please try again.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          }
        } else {
          console.log("Setting isTokenRequired to true");
          setIsTokenRequired(true);
          setSnackbarMessage("Two-factor authentication token is required!");
          setSnackbarSeverity("info");
          setSnackbarOpen(true);
        }
      } else {
        console.log("Setting isTokenRequired to true");
        setIsTokenRequired(true);
        setSnackbarMessage("Two-factor authentication token is required!");
        setSnackbarSeverity("info");
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Grid container sx={{ height: "100vh", backgroundColor: "#00BD82" }}>
      {/* Left Section */}
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          backgroundColor: "#00BD82",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          justifySelf: "center",
          alignSelf: "center",
          alignItems: "center",
          padding: 4,
          color: "#fff",
          height: "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "normal",
            marginBottom: 2,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          Grow your business with confidence—
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Poppins, sans-serif",
          }}
        >
          <strong style={{ color: "#333", fontWeight: "600px" }}>
            PlugLeads
          </strong>{" "}
          simplifies your marketing journey.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            borderRadius: "20px",
            marginTop: 4,
            position: "relative",
            bottom: "-15px",
          }}
        >
          <img
            style={{ width: "400px" }}
            src="login.gif"
            alt="Illustration_SignUp"
          />
        </Box>
      </Grid>

      {/* Right Section */}
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
          borderTopLeftRadius: "40px",
          height: "100%",
          borderBottomLeftRadius: "40px",
          border: "0px solid #000",
          backgroundColor: "#EFE7D7",
          boxShadow: "-4px -2px 2px #555",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderRadius: "10px",
            padding: 3,
          }}
        >
          <Box sx={{ textAlign: "center", alignSelf: "center" }}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "bold",
                marginBottom: 2,
                fontSize: "30px",
              }}
            >
              Hello again
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "normal",
                marginBottom: 2,
                fontSize: "20px",
                width: "80%",
                justifySelf: "center",
              }}
            >
              Welcome back, before logging in, make sure you enter account
              correctly
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              justifySelf: "center",
              marginTop: 2,
            }}
          >
            <TextField
              label="Email"
              name="username"
              variant="standard"
              fullWidth
              margin="normal"
              value={formData.username}
              onChange={handleChange}
              type="text"
              required
              error={Boolean(errors.username)}
              helperText={errors.username}
            />
            <TextField
              label="Password"
              name="password"
              variant="standard"
              fullWidth
              margin="normal"
              value={formData.password}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              required
              error={Boolean(errors.password)}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControlLabel
              control={
                <Switch
                  checked={isFirstTime}
                  onChange={handleFirstTimeToggle}
                  name="isFirstTime"
                  color="primary"
                />
              }
              label="Is This Your First Time Login?"
            /> */}
            {/* Conditionally render Two Factor Token input */}
            {isTokenRequired && (
              <>
                <TextField
                  label="Two Factor Token"
                  name="two_factor_token"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  value={formData.two_factor_token}
                  onChange={handleChange}
                  type="text"
                  required
                  error={Boolean(errors.two_factor_token)}
                  helperText={errors.two_factor_token}
                />
              </>
            )}

            <Box sx={{ width: "100%" }}>
              {snackbarMessage && (
                <Alert
                  severity={snackbarSeverity}
                  onClose={() => setSnackbarMessage("")}
                  style={{ marginTop: "10px" }}
                >
                  {snackbarMessage}
                </Alert>
              )}
            </Box>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              sx={{
                marginTop: "20px",
                borderRadius: "20px",
                color: "#222",
                textTransform: "none",
                fontWeight: "600px",
                background: "#FFDD00",
                "&:hover": {
                  backgroundColor: "#FFDD00",
                },
              }}
            >
              Sign In
            </Button>

            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <span>Don't have an account? </span>
              <Link
                to="/signup"
                style={{ textDecoration: "none", color: "#1976d2" }}
              >
                Register
              </Link>
            </div>
          </Box>
        </Box>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Scan the QR Code</DialogTitle>
          <DialogContent style={{ textAlign: "center" }}>
            {qrCode ? (
              <div>
                <h3>Scan this QR code to complete 2FA setup:</h3>
                <img
                  src={`data:image/png;base64,${qrCode}`}
                  alt="QR Code"
                  style={{ width: "100%", maxWidth: "300px", height: "auto" }}
                />
              </div>
            ) : (
              <p>Loading QR code...</p>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default SignIn;
