import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Box, Divider } from "@mui/material";
import Sidebar from "./sidebar";
import TopAppBar from "./TopAppBar";

const Settings = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };
  const sections = [
    {
      title: "GENERAL",
      links: [
        { label: "Profile", path: "/profile" },
        { label: "Company Details", path: "/company-details" },
        { label: "Notifications", path: "/notifications" },
        { label: "Subscription", path: "/license" },
        { label: "Compliance Settings", path: "/compliance-settings" },
        { label: "Manage Opt-in", path: "/manage-opt-in" },
      ],
    },
    {
      title: "USERS AND CONTROL",
      links: [
        { label: "Add Users", path: "/tenantuser" },
        { label: "LinkedIn Roles and Privileges", path: "/link" },
        { label: "Workspaces", path: "/workspaces" },
      ],
    },
    {
      title: "CAMPAIGN POLICY",
      links: [
        { label: "Email Limits", path: "/email-limits" },
        { label: "Campaign Tracking", path: "/campaign-tracking" },
        { label: "Content Approval", path: "/content-approval" },
        { label: "Export Policy", path: "/export-policy" },
      ],
    },
  ];

  return (
    <Box>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <Box
        sx={{
          width: sidebarOpen ? "70%" : "90%",
          marginLeft: sidebarOpen ? "250px" : "80px",
          p: 4,
          backgroundColor: "transparent",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "normal", mb: 3, textAlign: "left" }}
        >
          Settings
        </Typography>
        <Divider sx={{ mb: 0, borderColor: "#ccc" }} />

        <Grid container spacing={4} justifyContent="center">
          {sections.map((section, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                elevation={3}
                sx={{
                  p: 3,
                  borderRadius: 2,
                  textAlign: "left",
                  backgroundColor: "transparent",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: "bold", color: "#222" }}
                >
                  {section.title}
                </Typography>
                <Box component="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
                  {section.links.map((link, idx) => (
                    <li key={idx} style={{ margin: "8px 0" }}>
                      <Typography
                        component={Link}
                        to={link.path}
                        sx={{
                          textDecoration: "none",
                          color: "#349bee",
                          fontSize: "1rem",
                          "&:hover": {
                            textDecoration: "underline",
                            color: "00BD82",
                          },
                        }}
                      >
                        {link.label}
                      </Typography>
                    </li>
                  ))}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Settings;
