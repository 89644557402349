import React, { useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Divider,
  Button,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Generatedcontent = ({
  data: initialData,
  id,
  emailBody,
  subjectLine,
  tone,
  callToAction,
}) => {
  const [data, setData] = useState(initialData || {}); // State for the data
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");

  const handleApprove = (content) => {
    console.log("Approving content:", content);
    navigate("/leads");
  };

  const handlegenerate = async () => {
    setIsLoading(true); // Start loading
    const formData = new FormData();
    formData.append("campaign_id", id);
    formData.append("email_body", emailBody);
    formData.append("subject_line", subjectLine);
    formData.append("tone", tone);
    formData.append("call_to_action", callToAction);
    formData.append("about_content", "about company "); // Replace with actual 'about' content
    formData.append("services_content", "about this company ");
    formData.append("prompt", "prompt text"); // Replace with your actual prompt

    try {
      const response = await axios.post(
        "https://plugleads.in/api/campaign-content/create-content/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the Bearer token
          },
        }
      );

      if (response.status === 200) {
        console.log("Regenerated content:", response.data);
        setData(response.data); // Update the data state with the new response
      } else {
        console.error(
          `Failed to regenerate content. Status code: ${response.status}`
        );
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "token_not_valid"
      ) {
        console.error("Token is invalid or expired. Logging out...");
        // Clear token and redirect to login page
        localStorage.removeItem("token"); // or sessionStorage.removeItem("token")
        window.location.href = "/signin"; // Replace with your login page path
      } else {
        console.error("Error posting data:", error);
      }
      console.error("Error during regeneration:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <Box sx={{ width: "80%",display:"flex",justifySelf:'center' }}>
      <Box
        sx={{
          margin: "20px auto",
          padding: "24px",
          borderRadius: "12px",
          background: "linear-gradient(135deg, #f8f9fa, #e9ecef)",
          boxShadow: "0 6px 20px rgba(0, 0, 0, 0.15)",
        }}
      >
        {/* Loading Indicator */}
        {isLoading ? (
          <Box sx={{ textAlign: "center", marginTop: "20px" }}>
            <CircularProgress color="primary" />
            <Typography sx={{ marginTop: "10px" }}>Generating...</Typography>
          </Box>
        ) : (
          <>
            {/* Subject */}
            <Box
              sx={{
                padding: "12px 0",
                textAlign: "center",
                borderBottom: "2px solid #dee2e6",
                mb: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "#3f51b5",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                }}
              >
                {data?.subject || "No Subject"}
              </Typography>
            </Box>

            {/* Summary */}
            <Box
              sx={{
                margin: "20px 0",
                padding: "16px",
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.05)",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#009688",
                    marginRight: "5px",
                  }}
                >
                  Subject:
                </span>
                {data?.subject || "No Subject"}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#009688",
                    marginRight: "5px",
                  }}
                >
                  Summary:
                </span>
                {data?.summary || "No Summary"}
              </Typography>
            </Box>

            {/* Dynamic Content */}
            {data &&
            Object.keys(data).filter((key) => key.startsWith("content_"))
              .length > 0 ? (
              Object.keys(data)
                .filter((key) => key.startsWith("content_"))
                .map((key, index) => (
                  <Box
                    key={index}
                    sx={{
                      padding: "20px",
                      borderRadius: "8px",
                      backgroundColor: "#fff",
                      boxShadow: "inset 0 0 12px rgba(0, 0, 0, 0.05)",
                      transition: "transform 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.02)",
                        boxShadow: "inset 0 0 20px rgba(0, 0, 0, 0.1)",
                      },
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#555",
                        fontSize: "15px",
                        lineHeight: "1.6",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#3f51b5",
                          marginRight: "5px",
                        }}
                      >
                        Content {index + 1}:
                      </span>
                      {data[key]}
                    </Typography>

                    {index <
                      Object.keys(data).filter((key) =>
                        key.startsWith("content_")
                      ).length -
                        1 && <Divider sx={{ my: 2 }} />}
                  </Box>
                ))
            ) : (
              <Typography
                variant="body2"
                sx={{ width: "100%", color: "#999", fontSize: "15px" }}
              >
                No content available.
              </Typography>
            )}
          </>
        )}

        {/* Action Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2,width:'80%',justifySelf:'center' }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ margin: "10px", width: "50%", textTransform: "none" }}
            onClick={handlegenerate}
            disabled={isLoading}
          >
            Regenerate
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ margin: "10px", width: "50%", textTransform: "none" }}
            onClick={() => handleApprove(data)}
          >
            Approve
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Generatedcontent;
