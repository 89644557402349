import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Badge,
  useTheme,
  useMediaQuery,
  IconButton,
  styled,
} from "@mui/material";
import {
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
  Dashboard as DashboardIcon,
  Campaign as CampaignIcon,
  Verified as VerifiedIcon,
  Home,
  ExitToApp as ExitToAppIcon,
  Settings,
} from "@mui/icons-material"; // Material-UI Icons
import PersonIcon from "@mui/icons-material/Person";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useNavigate, useLocation } from "react-router-dom";

// Custom hook for colors from theme
const useAppColors = () => ({
  primary: "#c1761f",
  secondary: "#4062ad",
  darkAccent: "#ffdd00",
  lightBackground: "#ffffff",
  highlight: "#fff2cc",
  darkBackground: "#121926",
  greyAccent: "#c0c6ce",
});

const SidebarItem = styled(ListItem)(({ theme, sidebarOpen }) => ({
  padding: theme.spacing(1),
  display: "flex",
  justifyContent: sidebarOpen ? "flex-start" : "center",
  minHeight: 48,
  borderRadius: theme.shape.borderRadius,
  transition: "padding 0.3s, background-color 0.3s",
  "&:hover": {
    backgroundColor: "#fff2cc",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
}));

const SidebarIcon = styled(ListItemIcon)(({ isActive }) => ({
  justifyContent: "center",
  fontSize: 24,
  color: isActive ? "#4062ad" : "#363636",
  transition: "color 0.3s",
}));

const SidebarText = styled(ListItemText)(({ sidebarOpen }) => ({
  display: sidebarOpen ? "inline" : "none",
  transition: "display 0.3s",
}));

const sections = [
  {
    items: [
      { text: "Dashboard", icon: <DashboardIcon />, path: "dashboard" },
      { text: "Campaigns", icon: <CampaignIcon />, path: "createcampaign" },
      { text: "Leads", icon: <VerifiedIcon />, path: "leads" },
      { text: "Email Generation", icon: <FileCopyIcon />, path: "promptgen" },
    ],
  },
];

const drawerWidth = 240;
const closedDrawerWidth = 72;

const Sidebar = ({ sidebarOpen, toggleSidebar, notificationCount }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = useAppColors();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const isActive = (path) => location.pathname === `/${path}`;

  const drawerContent = (
    <Box
      sx={{
        width: sidebarOpen ? drawerWidth : closedDrawerWidth,
        height: "100%",
        bgcolor: colors.lightBackground,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        pt: theme.spacing(8),
        transition: "width 0.3s ease",
        overflow: "hidden",
      }}
    >
      <List>
        {sections.map((section) =>
          section.items.map((item) => (
            <SidebarItem
              key={item.text}
              button
              onClick={() => navigate(`/${item.path}`)}
              sidebarOpen={sidebarOpen}
              sx={{
                backgroundColor: isActive(item.path)
                  ? colors.highlight
                  : "transparent",
              }}
            >
              <SidebarIcon
                isActive={isActive(item.path)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item.icon}
                {!sidebarOpen && <p style={{ fontSize: "8px" }}>{item.text}</p>}
              </SidebarIcon>
              <SidebarText sidebarOpen={sidebarOpen}>{item.text}</SidebarText>
            </SidebarItem>
          ))
        )}
      </List>

      <List>
        <SidebarItem
          button
          onClick={() => navigate("/userprofile")}
          sidebarOpen={sidebarOpen}
        >
          <SidebarIcon isActive={false}>
            <PersonIcon />
          </SidebarIcon>
          <SidebarText sidebarOpen={sidebarOpen}>Profile</SidebarText>
        </SidebarItem>
        <SidebarItem
          button
          onClick={() => navigate("/settings1")}
          sidebarOpen={sidebarOpen}
        >
          <SidebarIcon isActive={false}>
            <Settings />
            <Badge badgeContent={notificationCount} color="error"></Badge>
          </SidebarIcon>
          <SidebarText sidebarOpen={sidebarOpen}>Settings</SidebarText>
        </SidebarItem>
        <SidebarItem
          button
          onClick={() => {
            localStorage.clear();
            window.location.href = "/signin";
          }}
          sidebarOpen={sidebarOpen}
        >
          <SidebarIcon isActive={false}>
            <ExitToAppIcon />
          </SidebarIcon>
          <SidebarText sidebarOpen={sidebarOpen}>Logout</SidebarText>
        </SidebarItem>
      </List>
    </Box>
  );

  return (
    <>
      {/* Sidebar Drawer */}
      <Drawer
        anchor="left"
        open={sidebarOpen}
        variant={isMobile ? "temporary" : "permanent"}
        onClose={toggleSidebar}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
            borderRight: "1px solid #333",
            width: sidebarOpen ? drawerWidth : closedDrawerWidth,
            transition: "width 0.3s ease",
          },
        }}
      >
        <Box
          sx={{
            top: "40px",
            position: "relative",
            marginTop: "40px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            justifySelf: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <img
            src="Assets/images/logoplugleads.svg"
            alt="Logo_"
            width={sidebarOpen ? "150px" : "68px"}
            height={sidebarOpen ? "100" : "50px"}
            padding={"10px"}
          />
        </Box>

        {drawerContent}
      </Drawer>

      {/* Menu Button (toggle sidebar) */}
      <Box
        sx={{
          position: "fixed",
          top: "20px",
          left: "10px",
          zIndex: 1201, // Ensures it is above the sidebar
        }}
      >
        <IconButton color="inherit" onClick={toggleSidebar}>
          {sidebarOpen ? (
            <MenuOpenIcon sx={{ color: "#4062ad" }} />
          ) : (
            <MenuIcon sx={{ color: "#4062ad" }} />
          )}
        </IconButton>
      </Box>
    </>
  );
};

export default Sidebar;
