import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    // confirmPassword: "",
    // termsAccepted: false,
  });
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password: "",
    // confirmPassword: "",
    // termsAccepted: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    // Reset error state when user starts editing the field
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  {
    snackbarMessage &&
      setTimeout(() => {
        setSnackbarMessage(""); // Clear the message after 3 seconds
      }, 3000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = {};
    let isValid = true;

    // Validation
    if (!formData.username) {
      formErrors.username = "Full Name is required";
      isValid = false;
    }
    if (!formData.email) {
      formErrors.email = "Email is required";
      isValid = false;
    }
    if (!formData.password) {
      formErrors.password = "Password is required";
      isValid = false;
    }
    if (formData.password !== formData.confirmPassword) {
      formErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }
    if (!formData.termsAccepted) {
      // Open the Terms and Conditions dialog if not accepted
      openDialog("terms");
      return;
    }

    if (!isValid) {
      setErrors(formErrors);
      return;
    }

    // Make API call if validation passes
    try {
      const response = await axios.post(
        "https://management.plugleads.in/users/register/",
        formData
      ); // Replace with your API endpoint
      setSnackbarMessage("Signup successful!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setOpen(true);
      setQrCode(response.data.two_factor.qr_code); // Store the QR code image URL in state
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "token_not_valid"
      ) {
        console.error("Token is invalid or expired. Logging out...");
        // Clear token and redirect to login page
        localStorage.removeItem("token"); // or sessionStorage.removeItem("token")
        window.location.href = "/signin"; // Replace with your login page path
      } else {
        console.error("Error posting data:", error);
      }
      console.error("Error during signup:", error);
      setSnackbarMessage("Signup failed. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const openDialog = () => {
    setOpenTermsDialog(true); // Open the dialog
  };

  const handleAgreeTerms = () => {
    setFormData((prevData) => ({
      ...prevData,
      termsAccepted: true, // Set the termsAccepted state to true
    }));
    setOpenTermsDialog(false); // Close the modal
  };

  return (
    <Grid container sx={{ height: "100vh", backgroundColor: "#00BD82" }}>
      {/* Left Section */}
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          backgroundColor: "#00BD82",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          justifySelf: "center",
          alignSelf: "center",
          alignItems: "center",
          padding: 4,
          color: "#fff",
          height: "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "normal",
            marginBottom: 2,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          Grow your business with confidence—
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Poppins, sans-serif",
          }}
        >
          <strong style={{ color: "#333", fontWeight: "600px" }}>
            PlugLeads
          </strong>{" "}
          simplifies your marketing journey.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            borderRadius: "20px",
            marginTop: 4,
            position: "relative",
            bottom: "-15px",
          }}
        >
          <img
            style={{ width: "400px" }}
            src="login.gif"
            alt="Illustration_SignUp"
          />
        </Box>
      </Grid>

      {/* Right Section */}
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
          borderTopLeftRadius: "40px",
          height: "100%",
          borderBottomLeftRadius: "40px",
          border: "0px solid #000",
          backgroundColor: "#EFE7D7",
          boxShadow: "-4px -2px 2px #555",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderRadius: "10px",
            padding: 3,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              marginBottom: 2,
            }}
          >
            Create Account
          </Typography>

          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              justifySelf: "center",
            }}
          >
            <TextField
              label="Email"
              name="email"
              variant="standard"
              fullWidth
              margin="normal"
              value={formData.email}
              onChange={(e) => {
                const newEmail = e.target.value;
                setFormData({
                  ...formData,
                  email: newEmail, // Update email field
                  username: newEmail, // Automatically update username to match email
                });
              }}
              type="email"
              required
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
            <TextField
              label="Password"
              name="password"
              variant="standard"
              fullWidth
              margin="normal"
              value={formData.password}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              required
              error={Boolean(errors.password)}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              variant="standard"
              fullWidth
              margin="normal"
              value={formData.confirmPassword}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              required
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="termsAccepted"
                  checked={formData.termsAccepted}
                  onChange={handleChange}
                />
              }
              label={
                <>
                  I agreed to the{" "}
                  <span
                    onClick={() => openDialog("terms")}
                    style={{
                      color: "#3f51b5",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Terms of Service & Privacy Policy
                  </span>{" "}
                </>
              }
            />

            <Box sx={{ width: "100%" }}>
              {snackbarMessage && (
                <Alert
                  severity={snackbarSeverity}
                  onClose={() => setSnackbarMessage("")}
                  style={{ marginTop: "10px" }}
                >
                  {snackbarMessage}
                </Alert>
              )}
            </Box>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              sx={{
                marginTop: "20px",
                borderRadius: "20px",
                color: "#222",
                textTransform: "none",
                backgroundColor: "#FFDD00",
                "&:hover": {
                  backgroundColor: "#FFDD00",
                },
              }}
            >
              Sign Up
            </Button>

            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <span>Already have an account? </span>
              <Link
                to="/signin"
                style={{ textDecoration: "none", color: "#1976d2" }}
              >
                Login
              </Link>
            </div>
          </Box>
        </Box>
      </Grid>

      {/* Terms and Privacy Dialog */}
      <Dialog open={openTermsDialog} onClose={() => setOpenTermsDialog(false)}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          {/* Replace this with actual Terms or Privacy Policy content */}
          <Box>
            {" Here are the Terms of Service Here is the Privacy Policy"}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTermsDialog(false)} color="primary">
            Close
          </Button>
          <Button onClick={handleAgreeTerms} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Scan the QR Code</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          {qrCode && (
            <div>
              <h3>Scan this QR code to complete 2FA setup:</h3>
              <img
                src={`data:image/png;base64,${qrCode}`}
                alt="QR Code"
                style={{ width: "100%", maxWidth: "300px", height: "auto" }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default SignUp;
