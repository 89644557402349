import React from "react";
import "./App.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PromptGenerator from "./components/promtgenerator";
import Createcampaign from "./components/createcampaign";
import Generatedcontent from "./components/Generatedcontent";
import Leads from "./components/Leads";
import NewLead from "./components/newlead";
import SignUp from "./components/Authorization/signup";
import SignIn from "./components/Authorization/signIn";
import Dashboard from "./components/dashboard";
import UserProfile from "./components/userprofile";
import LinkLinkedIn from "./components/LinkLinkedIn";
import UserLicensesManagement from "./components/UserLicensesManagement";
import Settings from "./components/settings";
import TenantUser from "./components/tenantuser";
import PrivateRoute from "./components/PrivateRoute";
import WebSocketProvider from "./components/webSocketcontext";
import OTPModal from "./components/OTPModal";
import { NotificationProvider } from "./components/NotificationContext";
import LandingPage from "./components/Landing/LandingPage";
import Settings1 from "./components/Settings1";
import Profile from "./components/profile";

function App() {
  return (
    <WebSocketProvider>
      {" "}
      {/* Wrap the app with WebSocketProvider */}
      <NotificationProvider>
        <Router>
          <div>
            {/* OTPModal is globally rendered */}
            <OTPModal />

            <Routes>
              {/* Authentication Routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />

              {/* Private Routes */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/tenantuser" element={<TenantUser />} />
              <Route path="/settings" element={<Settings />} />
              {/* <Route
                path="/settings"
                element={<PrivateRoute element={Settings} />}
              /> */}

              {/* Public Routes */}
              <Route path="/createcampaign" element={<Createcampaign />} />
              <Route path="/promptgen" element={<PromptGenerator />} />
              <Route path="/gen" element={<Generatedcontent />} />
              <Route path="/leads" element={<Leads />} />
              <Route path="/newlead" element={<NewLead />} />
              <Route path="/userprofile" element={<UserProfile />} />
              <Route path="/link" element={<LinkLinkedIn />} />
              <Route path="/settings1" element={<Settings1 />} />
              <Route path="/license" element={<UserLicensesManagement />} />
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </div>
        </Router>
      </NotificationProvider>
    </WebSocketProvider>
  );
}

export default App;
