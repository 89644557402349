import React, { useState, useEffect } from "react";
import FormData from "form-data";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import Sidebar from "./sidebar";
import CreateIcon from "@mui/icons-material/Create";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import axios from "axios";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Grid from "@mui/material/Grid";
import locations from "./locations.json";
import Industry from "./Industries.json";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Cjobtitles from "./Jobtitle.json";
import Autocomplete from "@mui/material/Autocomplete";
import Role from "./Roles.json";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
function Createcampaign() {
  const [openModal, setOpenModal] = useState(false);

  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedHeadCount, setSelectedHeadCount] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [selectedFunction, setSelectedFunction] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [currentjob, setCurrentjob] = useState("");
  const [campaignDetails, setCampaignDetails] = useState({
    department: "",
    designation: "",
    country: "",
    industry: "",
  });
  const [positionlevel, setPositionlevel] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [pastjobtitle, setPastjobtitle] = useState("");
  const [currentcompanyyears, setCurrentcompanyyears] = useState("");
  const [currentposyears, setCurrentposyears] = useState("");
  const [geography, setGeography] = useState("");
  const [yearsofexp, setYearsofexp] = useState("");
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Renamed from selectedCampaign
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [AllIndustries, setAllIndustries] = useState([]); // Store all industries fetched from API
  const [Industries, setIndustries] = useState([]);
  const [headCountArray, setHeadCountArray] = useState([]);
  const [industries, setindustries] = useState([]);
  const token = localStorage.getItem("access_token");

  // const postData = async () => {
  //   try {
  //     // Initialize an array to store the IDs of the created items
  //     const ids = [];
  //     // Loop through the values array and post each value to the API
  //     for (const value of Industry) {
  //       const response = await axios.post(
  //         `https://plugleads.in/api/industries/`,
  //         value,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization:`Bearer ${token}`
  //           },
  //         }
  //       );

  //       if (response.status === 201) {
  //         // Check if the response status is successful
  //         console.log("Data posted successfully:", response.data);
  //         ids.push(response.data.id); // Store the id of the created value
  //       } else {
  //         console.error("Failed to post data:", response);
  //       }
  //     }

  //     // Return the array of created IDs
  //   } catch (error) {
  //     console.error("Error posting data:", error);
  //   }
  // };

  useEffect(() => {
    const results = campaigns.filter((campaign) =>
      campaign.campaign_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCampaigns(results);
  }, [searchTerm, campaigns]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Change this based on your requirements
  const totalItems = 100; // Total number of items (for example, campaigns)

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // campaign usestates
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [industry, setIndustry] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const data = Array.from({ length: 20 }, (_, index) => `Option ${index + 1}`);
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [promotion, setPromotion] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [leadsPerDay, setLeadsPerDay] = useState("");
  const [leadsRequired, setLeadsRequired] = useState("");
  const [options, setOptions] = useState([]);
  const [numFollowups, setNumFollowups] = useState();
  const [geographyInput, setGeographyInput] = useState("");
  const [industryInput, setIndustryInput] = useState("");
  const [id, setId] = useState("");
  const [pastJobTitle, setPastJobTitle] = useState("");
  const [roles, setRoles] = useState(""); // Add this line
  const functions = [{ val: "Marketing" }, { val: "Sales" }];
  const [error, setError] = useState(false);
  const [positionLevelArray, setPositionLevelArray] = useState([]);
  const [POS_Level, setPOS_Level] = useState([]);
  const [headCountOptions, setHeadCountOptions] = useState([]);
  const [companyHeadCountInput, setCompanyHeadCountInput] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("Upload Template");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [headCountIds, setHeadCountIds] = useState([]);
  const [customerName, setCustometName] = useState();
  const navigate = useNavigate();
  const [company, setCompany] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false); // Initial state for sidebar
  const [leadInputType, setLeadInputType] = React.useState(""); // Track if "Upload Leads" or "Select Leads" is chosen
  const [selectedLead, setSelectedLead] = React.useState(""); // Track the selected lead
  const [leadOptions, setLeadOptions] = React.useState([
    { id: "1", name: "Lead 1" },
    { id: "2", name: "Lead 2" },
  ]); // Dummy data for dropdown

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  const companyTypeOptions = [
    { val: "Public Company" },
    { val: "Privately Held" },
    { val: "Non Profit" },
    { val: "Educational Institution" },
    { val: "Partnership" },
    { val: "Self Employed" },
    { val: "Self Owned" },
    { val: "Government Agency" },
  ];

  useEffect(() => {
    const storedCompanyName = localStorage.getItem("companyname") || "";
    setCompany(storedCompanyName);
  }, []);

  // State variables for company type
  const [companyTypeInput, setCompanyTypeInput] = useState("");

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get(
        "https://plugleads.in/api/campaign/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the Bearer token in the headers
          },
        }
      );

      setCampaigns(response.data); // Update state with the fetched data
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  // useEffect(() => {
  //   console.log(startDate,"SD");
  //   console.log(endDate,"ED")
  //   if (startDate && endDate) {
  //     calculateNumFollowups(startDate, endDate);
  //   }
  // }, [startDate, endDate]);

  function calculateNumFollowups(start, end) {
    const timeDifference = end - start;
    const weeksDifference = Math.floor(
      timeDifference / (7 * 24 * 60 * 60 * 1000)
    );

    return weeksDifference;
  }

  const handleFileUpload = (file) => {
    console.log("Uploaded file:", file); // Log the file
    setUploadedFile(file); // Store the uploaded file in state
    // Add your file upload logic here, e.g., API call
  };

  const handleFileChange = (file) => {
    if (file) {
      setFileName(file.name); // Update the file name for display
      handleFileUpload(file); // Call the parent handler to process the file
    }
  };

 const fetchProfileData = async () => {
  try {
    let token = localStorage.getItem('access_token')
    console.log(token,"TTTTT");
    // Make the GET request with headers and Bearer token
    const response = await axios.get("https://plugleads.in/api/tenants/", {
      headers: {
        // Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Use token from localStorage
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        "Content-Type": "application/json", // Optional: specify content type
      },
    });

    // Handle the response
    if (response.status === 200) {
      const email = localStorage.getItem("email"); // Example email address

      // Extract the domain part of the email
      const emailDomain = email.split("@")[1];

      // Find the name that matches the domain from the response data
      const matchedTenant = response.data.find((tenant) =>
        tenant.name.toLowerCase().includes(emailDomain.toLowerCase())
      );

      if (matchedTenant) {
        // Set the matched tenant name to a variable or state
        const matchedTenantName = matchedTenant.name;
        console.log("Matched Tenant:", matchedTenantName);

        // You can set this value to a state if needed
        setCustometName(matchedTenantName);
      } else {
        console.log("No matching tenant found for the email domain.");
      }
    }
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.code === "token_not_valid"
    ) {
      console.error("Token is invalid or expired.");

      try {
        // Refresh the access token
        const refreshToken = localStorage.getItem("refresh_token");
        if (!refreshToken) {
          throw new Error("Refresh token is not available in localStorage.");
        }

        const refreshResponse = await axios.post(
          "https://management.plugleads.in/users/token/refresh/",
          { refresh: refreshToken } // Pass the /api/tenants/refresh token
        );

        // Update the access token in localStorage
        const newAccessToken = refreshResponse.data.access;
        localStorage.setItem("access_token", newAccessToken);

        // Retry the original request with the new access token
        const retryResponse = await axios.get("https://plugleads.in/api/tenants/", {
          headers: {
            Authorization: `Bearer ${newAccessToken}`,
            "Content-Type": "application/json",
          },
        });

        // Handle the retry response as before
        if (retryResponse.status === 200) {
          const email = localStorage.getItem("email");
          const emailDomain = email.split("@")[1];

          const matchedTenant = retryResponse.data.find((tenant) =>
            tenant.name.toLowerCase().includes(emailDomain.toLowerCase())
          );

          if (matchedTenant) {
            const matchedTenantName = matchedTenant.name;
            console.log("Matched Tenant after retry:", matchedTenantName);
            setCustometName(matchedTenantName);
          } else {
            console.log("No matching tenant found for the email domain after retry.");
          }
        }
      } catch (refreshError) {
        console.error("Error refreshing token:", refreshError);
        // Optionally, handle logout or notify user
      }
    } else {
      console.error("Error fetching profile data:", error);
    }

    setError("Error fetching profile data"); // Set error if fetch fails
  }
};


  useEffect(() => {
    fetchProfileData();
  }, []);

  useEffect(() => {
    const formattedCampaignName = [
      geography, // Geography
      state, // State
      Industries, // Industry
      selectedFunction, // Function
      currentjob, // Current Job Title
      promotion, // Promotion
    ]
      .filter(Boolean) // Filter out falsy values
      .join("_"); // Join values with an underscore

    setCampaignName(formattedCampaignName);
  }, [geography, state, Industries, selectedFunction, currentjob, promotion]);

  // Handle tab change

  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Get the current time
  const now = new Date();

  // Format time_of_day as HH:MM:SS
  const time_of_day = now.toTimeString().split(" ")[0];

  const convertToISO = (date) => {
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? null : parsedDate.toISOString();
  };

  // Creating campaign name
  const handleCreateCampaign = async () => {
    setIsLoading(true);
    try {
      if (!selectedFunction) {
        alert("Please select a job function.");
        setIsLoading(false); // Stop loading spinner
        return;
      }
  
      const startDateISO = convertToISO(startDate);
      const endDateISO = convertToISO(endDate);
  
      if (!startDateISO || !endDateISO || isNaN(new Date(startDateISO)) || isNaN(new Date(endDateISO))) {
        alert("Invalid or missing date format. Please correct the dates.");
        setIsLoading(false);
        return;
      }
  
      const leadsRequiredInt = parseInt(leadsRequired, 10);
      const leadsPerDayInt = parseInt(leadsPerDay, 10);
  
      const startDateObj = new Date(startDateISO);
      const endDateObj = new Date(endDateISO);
      const weeksBetween = Math.floor((endDateObj - startDateObj) / (7 * 24 * 60 * 60 * 1000));
  
      const scheduled_at = new Date().toISOString();
  
      const campaignData = {
        campaign_for_company_name: companyName || "",
        company_name: customerName || "",
        geography: geography || "",
        state: state || "",
        industries: industries || "",
        company_type: companyType || null,
        function: selectedFunction || "",
        designation: currentjob || "",
        promotion: promotion || "",
        campaign_name: campaignName || "",
        start_date: startDateISO,
        end_date: endDateISO,
        num_followups: weeksBetween,
        number_of_leads_required: leadsRequiredInt,
        number_of_leads_per_day: leadsPerDayInt,
        campaign_type: "mail",
        days_to_next_followup: 5,
        time_of_day: time_of_day,
        scheduled_at: scheduled_at,
        seniority_levels: positionLevelArray.map((level) => level.id),
        company_headcounts: headCountIds,
      };
  
      const campaignResponse = await fetch(
        "https://plugleads.in/api/campaign/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            ...campaignData,
            campaign_target_type: "Regular",
          }),
        }
      );
  
      const campaignResult = await campaignResponse.json();
  
      if (!campaignResponse.ok || !campaignResult || !campaignResult.campaign) {
        throw new Error(campaignResult.message || "Failed to create campaign");
      }
  
      const campaignId = campaignResult.campaign.id;
      const campaignName1 = campaignResult.campaign.campaign_name;
  
      if (leadInputType === "upload") {
        const fileInput = document.getElementById("fileUploadInput");
        const file = fileInput?.files[0];
  
        if (!file) {
          alert("No file selected for upload.");
          return;
        }
  
        const formData = new FormData();
        formData.append("file", file);
        formData.append("campaign_id", campaignId);
  
        const uploadResponse = await fetch(
          "https://plugleads.in/api/mail-campaigns/upload_custom_leads/",
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
  
        const uploadResult = await uploadResponse.json();
  
        if (!uploadResponse.ok) {
          throw new Error(uploadResult.message || "File upload failed");
        }
      }
  
      fetchCampaigns();
      navigate("/promptgen", { state: { campaign_id: campaignId, campaign_name: campaignName1 } });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "token_not_valid"
      ) {
        try {
          const refreshResponse = await axios.post(
            `https://management.plugleads.in/users/token/refresh/`,
            { refresh_token: localStorage.getItem("refresh_token") }
          );
  
          const newToken = refreshResponse.data.access_token;
          localStorage.setItem("access_token", newToken);
  
          // Retry the request with the new access token
          handleCreateCampaign(); // Retry campaign creation
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          alert("Session expired. Please log in again.");
        }
      } else {
        console.error("Error in campaign creation or file upload:", error);
        alert("An error occurred while creating the campaign. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  




  /**
   * Upload leads file and associate it with a specific campaign.
   *
   * @param {string} filePath - Path to the file being uploaded.
   * @param {string} apiUrl - Endpoint URL for uploading leads.
   * @param {number} campaignId - Campaign ID to associate leads with.
   */
  const uploadCustomLeads = async (filePath, apiUrl, campaignId) => {
    try {
      // Prepare the form data
      const formData = new FormData();
      formData.append("file", fs.createReadStream(filePath));

      if (campaignId) {
        formData.append("campaign_id", campaignId);
      }

      // Axios request configuration
      const config = {
        method: "post",
        url: apiUrl,
        headers: {
          ...formData.getHeaders(),
          Authorization: `Bearer ${token}`, // Replace YOUR_ACCESS_TOKEN with the actual token
        },
        data: formData,
      };

      // Make the POST request
      const response = await axios(config);

      // Log the response
      console.log("Status Code:", response.status);
      console.log("Response Data:", response.data);

      if (response.status === 200) {
        console.log(
          `Leads uploaded successfully: ${response.data.leads_uploaded}`
        );
      } else {
        console.error(
          "Error:",
          response.data.error || "Unknown error occurred"
        );
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "token_not_valid"
      ) {
        console.error("Token is invalid or expired. Logging out...");
        // Clear token and redirect to login page
      } else {
        console.error("Error posting data:", error);
      }
      // Handle errors
      if (error.response) {
        console.error("Error Response:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  // Handle form submission

  const handleConfirm = async (e) => {
    // Perform submission logic here
    e.preventDefault(); // Prevent the form from refreshing

    // Ensure a campaign is selected
    if (!selectedCampaign) {
      alert("Please select a campaign");
      return;
    }
    // Construct the dynamic URL using the selected campaign ID
    const campaignId = selectedCampaign;
    const url = `https://plugleads.in/api/campaign/${campaignId}/add-lead-request-details/`;

    // Create a payload with the data you want to send
    const payload = {
      campaign: selectedCampaign,
      company_headcount: selectedHeadCount || null,
      company_type: companyType || null,
      current_job_title: currentjob || null,
      function: selectedFunction || null,
      geography: geography || null,
      industry: Industries || null,
      number_of_leads: campaignDetails.number_of_leads_required || "--",
      number_of_leads_uploaded:
        campaignDetails.number_of_leads_uploaded || "--",
      past_job_title: pastjobtitle || null,
      seniority_level: positionlevel || null,
      years_in_current_company: currentcompanyyears || null,
      years_in_current_position: currentposyears || null,
      years_of_experience: yearsofexp || null,
    };
    // console.log("payload Data:", payload);

    try {
      // Make the axios POST request
      const response = await axios.post(url, payload);

      // Handle success
      if (response.status === 201) {
        alert("submission is successfull!");
      }
    } catch (error) {
      // Handle error
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "token_not_valid"
      ) {
        console.error("Token is invalid or expired. Logging out...");
        // Clear token and redirect to login page
      } else {
        console.error("Error posting data:", error);
      }
      console.error("Error submitting data:", error);
      alert("There was an error submitting the data." + error.message);
    }
    console.log("Data submitted");
    setOpenModal(false);
  };

  const handleEdit = () => {
    setOpenModal(false);
  };

  const paginatedCampaigns = filteredCampaigns.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    const fetchPositionLevels = async () => {
      try {
        const response = await axios.get(
          "https://plugleads.in/api/seniority_levels/",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the Bearer token to the Authorization header
            },
          }
        );

        setPOS_Level(response.data); // Assuming the API returns an array of position levels
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === "token_not_valid"
        ) {
          console.error("Token is invalid or expired. Logging out...");
          // Clear token and redirect to login page
        } else {
          console.error("Error posting data:", error);
        }
        console.error("Error fetching position levels:", error);
      }
    };

    fetchPositionLevels();
  }, []);

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const response = await axios.get(
          "https://plugleads.in/api/industries/",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the Bearer token to the Authorization header
            },
          }
        );
        // Log the response to see the fetched data
        setAllIndustries(response.data); // Assuming the API returns an array of industries
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === "token_not_valid"
        ) {
          console.error("Token is invalid or expired. Logging out...");
          // Clear token and redirect to login page
        } else {
          console.error("Error posting data:", error);
        }
        console.error("Error fetching industries:", error);
      }
    };

    fetchIndustries();
  }, []);

  useEffect(() => {
    const fetchHeadCountOptions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://plugleads.in/api/company_head_counts/",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the Bearer token to the Authorization header
            },
          }
        );
        setHeadCountOptions(response.data); // Assuming the response contains an array of objects
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === "token_not_valid"
        ) {
          console.error("Token is invalid or expired. Logging out...");
          // Clear token and redirect to login page
        } else {
          console.error("Error posting data:", error);
        }
        console.error("Error fetching head count options:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHeadCountOptions();
  }, []);

  // Handle selection change

  const handleChange = (event, field) => {
    const { value } = event.target;

    if (field === "positionLevel") {
      setPositionLevelArray(value); // Update selected position levels
    } else if (field === "headCount") {
      if (value && Array.isArray(value)) {
        const selectedIds = value.map((item) => item.id);
        setHeadCountArray(value); // Keep the full objects in state for UI purposes
        setHeadCountIds(selectedIds); // Update the selected IDs
      }
    }
  };

  const handleIndustryChange = (event, newValue) => {
    // Extract the selected industry IDs from `newValue`
    const selectedIndustryIds = newValue.map((option) => option.id);
    setindustries(selectedIndustryIds);
    // Optionally, you can store the full objects for external use
    setIndustryInput(newValue);
  };

  return (
    <div>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <Container>
        <Box sx={{ width: "100%", marginTop: 3 }}>
          <Box
            sx={{
              padding: 3,
              alignSelf: "center",
              backgroundColor: "transparent",
              margin: "auto",
              borderRadius: "10px",
            }}
          >
             <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            {/* Main form starts */}
            <form style={{ alignItems: "center" }}>
              <Box sx={{ width: { xs: "100%", md: "fit-content" } }}>
                <Box
                  sx={{
                    display: "flex",
                    width: { xs: "100%", md: "80%" },
                    alignSelf: "center",
                    margin: "auto",
                    padding: "20px",
                    alignItems: "center", // Center the items vertically
                    justifyContent: "center", // Center the items horizontally
                  }}
                >
                  <CreateIcon
                    sx={{ color: "#1871ac", fontSize: "22px", marginRight: 1 }}
                  />{" "}
                  {/* Add the icon here */}
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 600,
                      textAlign: "center",
                      color: "#1871ac",
                      margin: "0px",
                    }}
                  >
                    Create a Campaign
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: 4,
                    width: { xs: "100%", md: "100%" },
                    padding: { xs: 2, md: 2 }, // Add padding around the content
                    backgroundImage:
                      "linear-gradient(to bottom, #FFF9E7, #FFFFFF)",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: 2, // Subtle shadow for depth
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: 2,
                      width:"100%",
                      alignItems: "center", // Center the items vertically
                      justifyContent: "start", // Center the items horizontally
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        color: "#00416A",
                        margin: "0px",
                      }}
                    >
                      Bussines Details
                    </Typography>
                    <InfoOutlinedIcon
                      dedIcon
                      sx={{ color: "#00416A", fontSize: "20px", marginLeft: 1 }}
                    />{" "}
                    {/* Add the icon here */}
                  </Box>

                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Campaign For Bussiness:
                      </Typography>
                      <TextField
                        placeholder="Enter Bussiness Name"
                        fullWidth
                        required
                        variant="outlined"
                        sx={{
                          backgroundColor: "#f7f8fa",
                          borderRadius: "10px",
                          "& .MuiInputLabel-root": {
                            color: "#555",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& input": {
                              textAlign: "start", // Center align the input text
                            },
                            "& fieldset": {
                              borderColor: "#d1d5db", // ca border color
                            },
                            "&:hover fieldset": {
                              borderColor: "#a0aec0", // Hover color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3f51b5", // Focused color
                            },
                          },
                        }}
                        value={company}
                        disabled
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Promotion:
                      </Typography>
                      <TextField
                        placeholder="Enter Promotion"
                        fullWidth
                        required
                        variant="outlined"
                        sx={{
                          backgroundColor: "#f7f8fa",
                          borderRadius: "10px",
                          "& .MuiInputLabel-root": {
                            color: "#555",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& input": {
                              textAlign: "start", // Center align the input text
                            },
                            "& fieldset": {
                              borderColor: "#d1d5db", // Custom border color
                            },
                            "&:hover fieldset": {
                              borderColor: "#a0aec0", // Hover color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3f51b5", // Focused color
                            },
                          },
                        }}
                        value={promotion}
                        onChange={(e) => setPromotion(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={4}>
                    {/* Start Date Field */}
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Start Date:
                      </Typography>
                      <TextField
                        type="date"
                        placeholder="Start Date"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        value={startDate}
                        onChange={(e) => setstartDate(e.target.value)}
                        sx={{
                          backgroundColor: "#f7f8fa",
                          borderRadius: "10px",
                          "& .MuiInputLabel-root": {
                            color: "#555",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& input": {
                              textAlign: "start", // Align the input text
                            },
                            "& fieldset": {
                              borderColor: "#d1d5db", // Custom border color
                            },
                            "&:hover fieldset": {
                              borderColor: "#a0aec0", // Hover color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3f51b5", // Focused color
                            },
                          },
                        }}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0], // Set the min date to today's date
                        }}
                      />
                    </Grid>

                    {/* End Date Field */}
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        End Date:
                      </Typography>
                      <TextField
                        type="date"
                        placeholder="End Date"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        value={endDate}
                        onChange={(e) => setendDate(e.target.value)}
                        sx={{
                          backgroundColor: "#f7f8fa",
                          borderRadius: "10px",
                          "& .MuiInputLabel-root": {
                            color: "#555",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& input": {
                              textAlign: "start", // Align the input text
                            },
                            "& fieldset": {
                              borderColor: "#d1d5db", // Custom border color
                            },
                            "&:hover fieldset": {
                              borderColor: "#a0aec0", // Hover color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3f51b5", // Focused color
                            },
                          },
                        }}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0], // Set the min date to today's date
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <h4
                        style={{
                          fontWeight: 400,
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Mails Per Day:
                      </h4>
                      <TextField
                        type="text"
                        placeholder="Mails Per Day"
                        fullWidth
                        required
                        value={leadsPerDay}
                        onChange={(e) => {
                          // Only allow numeric input
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            // This regex allows only digits (numbers)
                            setLeadsPerDay(value);
                          }
                        }}
                        sx={{
                          backgroundColor: "#f7f8fa",
                          borderRadius: "5px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    marginTop: 4,
                    display: "flex",
                    width: { xs: "100%", md: "100%" },
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: { xs: 2, md: 2 },
                    backgroundImage:
                      "linear-gradient(to left, rgba(255, 130, 84, 0.3),rgba(234, 60, 66, 0.1))",
                    borderRadius: "10px",
                    boxShadow: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      marginBottom: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        color: "#00416A",
                        margin: "0px",
                      }}
                    >
                      Select Option
                    </Typography>
                    <CheckCircleOutlineIcon
                      dedIcon
                      sx={{ color: "#00416A", fontSize: "20px", marginLeft: 1 }}
                    />
                  </Box>
                  <Box>
                    <Grid container spacing={1}>
                      {/* Radio Buttons for Upload and Select Leads */}
                      <Grid item xs={12} marginLeft={"20px"}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 400,
                            fontSize: "18px",
                            color: "#555",
                            marginBottom: "10px",
                          }}
                        >
                          Lead Input Method:
                        </Typography>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row // Align radio buttons in a row
                            value={leadInputType} // State for selected value
                            onChange={(event) =>
                              setLeadInputType(event.target.value)
                            } // Update state
                          >
                            <FormControlLabel
                              value="upload"
                              control={<Radio />}
                              label="Upload Leads"
                            />
                            <FormControlLabel
                              value="select"
                              control={<Radio />}
                              label="Request Leads"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                {/* Conditionally Render Input Fields Below Radio Buttons */}
                {leadInputType === "upload" && (
                  <Box
                    sx={{
                      marginTop: 4,
                      display: "flex",
                      width: { xs: "100%", md: "100%" },
                      flexDirection: "column",

                      justifyContent: "center",
                      padding: { xs: 2, md: 2 }, // Add padding around the content
                      backgroundImage:
                        "linear-gradient(to left, #D6E4FE, #EDF5FF)",
                      borderRadius: "10px", // Rounded corners
                      boxShadow: 2, // Subtle shadow for depth
                    }}
                  >
                    <Grid item xs={12} md={6} ml={2}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "#555",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Upload Leads:
                      </Typography>
                      {/* Download Excel Template Button */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {/* Upload File Button */}
                        <Button
                          variant="contained"
                          component="label"
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#3f51b5",
                            color: "#fff",
                            "&:hover": { backgroundColor: "#2c387e" },
                          }}
                        >
                          <UploadIcon sx={{ marginRight: "10px" }} />
                          {fileName} {/* Dynamically display the file name */}
                          <input
                            type="file"
                            hidden
                            id="fileUploadInput"
                            accept=".xlsx, .xls"
                            onChange={(e) =>
                              handleFileChange(e.target.files[0])
                            }
                          />
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{
                            textTransform: "none",
                            width: "fit-content",
                            color: "#00416A",
                            borderRadius: "50%",
                            borderColor: "#00416A",
                            padding: "14px",
                            "&:hover": {
                              backgroundColor: "rgba(0, 65, 106, 0.1)",
                              borderColor: "#00416A",
                            },
                            marginBottom: "10px",
                          }}
                          onClick={() => {
                            // Replace the URL with the actual link to your Excel template file
                            const templateUrl =
                              "/path-to-your-excel-template.xlsx";
                            window.open(templateUrl, "_blank");
                          }}
                        >
                          <img
                            src="Assets/images/ExcelFile.svg"
                            alt="Excel_Icon"
                            style={{ width: "24px", height: "24px" }}
                          />
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={12} md={6}>
                          <h4
                            style={{
                              fontWeight: 400,
                              color: "#555",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            Leads Required
                          </h4>
                          <TextField
                            type="text" // Changed from "number" to "text" to remove spinner arrows
                            placeholder="Enter Leads Required"
                            fullWidth
                            required
                            value={leadsRequired}
                            onChange={(e) => {
                              // Only allow numeric input
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                // This regex allows only digits (numbers)
                                setLeadsRequired(value);
                              }
                            }}
                            sx={{
                              backgroundColor: "#f7f8fa",
                              borderRadius: "5px",
                            }}
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                )}
                {/* BEST PATH-IN Section */}
                {leadInputType === "select" && (
                  <Box
                    sx={{
                      marginTop: 4,
                      display: "flex",
                      width: { xs: "100%", md: "100%" },
                      flexDirection: "column",

                      justifyContent: "center",
                      padding: { xs: 2, md: 2 }, // Add padding around the content
                      backgroundImage:
                        "linear-gradient(to left, #D6E4FE, #EDF5FF)",
                      borderRadius: "10px", // Rounded corners
                      boxShadow: 2, // Subtle shadow for depth
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center", // Center the items vertically
                        justifyContent: "start",
                        marginBottom: 2,
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 600,
                          color: "#00416A",
                          margin: "0px",
                        }}
                      >
                        Lead Request Details
                      </Typography>
                      <LightbulbOutlinedIcon
                        dedIcon
                        sx={{
                          color: "#00416A",
                          fontSize: "20px",
                          marginLeft: 1,
                        }}
                      />{" "}
                      {/* Add the icon here */}
                    </Box>
                    <Box>
                      {/* Location Field */}
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 400,
                              fontSize: "18px",
                              color: "#555",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            Location:
                          </Typography>
                          <Autocomplete
                            options={locations}
                            getOptionLabel={(option) => option.val || ""}
                            value={
                              locations.find(
                                (location) => location.val === geography
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              setGeography(newValue ? newValue.val : "");
                              setGeographyInput(newValue ? newValue.val : ""); // Update state for outside input
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Country"
                                variant="outlined"
                                fullWidth
                                sx={{
                                  backgroundColor: "#f7f8fa",
                                  borderRadius: "10px",
                                  "& .MuiInputLabel-root": {
                                    color: "#555",
                                  },
                                }}
                              />
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.val === value.val
                            }
                            noOptionsText="- - No options - -"
                          />
                        </Grid>

                        {/* Industry Field */}
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 400,
                              fontSize: "18px",
                              color: "#555",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            Industry:
                          </Typography>
                          <Autocomplete
                            multiple
                            options={AllIndustries} // Options fetched from API
                            getOptionLabel={(option) => option.name || ""} // Assuming `name` is the name of the industry
                            value={AllIndustries.filter((industry) =>
                              industries.includes(industry.id)
                            )} // Filter based on selected IDs
                            onChange={handleIndustryChange} // Update selected values on change
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Industry"
                                variant="outlined"
                                fullWidth
                                sx={{
                                  backgroundColor: "#f7f8fa",
                                  borderRadius: "10px",
                                  "& .MuiInputLabel-root": {
                                    color: "#555",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "& input": {
                                      textAlign: "start",
                                    },
                                    "& fieldset": {
                                      borderColor: "#d1d5db",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#a0aec0",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#3f51b5",
                                    },
                                  },
                                }}
                              />
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            } // Ensure correct comparison for selected value
                            noOptionsText="- - No options - -"
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={4}>
                        {/* Company Head Count Field */}
                        <Grid item xs={12} md={6}>
                          <h4
                            style={{
                              fontWeight: 400,
                              color: "#555",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            Company Head Count:
                          </h4>
                          <FormControl fullWidth>
                            <Select
                              labelId="head-count-label"
                              multiple
                              value={headCountArray} // Ensure value is the array of selected objects
                              onChange={(e) => handleChange(e, "headCount")}
                              renderValue={
                                (selected) =>
                                  selected
                                    .map((count) => count.range)
                                    .join(", ") // Display selected ranges as comma-separated
                              }
                              variant="outlined"
                              sx={{
                                backgroundColor: "#f7f8fa",
                                borderRadius: "5px",
                              }}
                            >
                              {headCountOptions.map((count) => (
                                <MenuItem key={count.id} value={count}>
                                  {" "}
                                  {/* Use the whole object as the value */}
                                  <Checkbox
                                    checked={headCountArray.some(
                                      (item) => item.id === count.id
                                    )}
                                  />
                                  <ListItemText primary={count.range} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 400,
                              fontSize: "18px",
                              color: "#555",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            Function:
                          </Typography>
                          <Autocomplete
                            options={Role}
                            getOptionLabel={(option) => option.val || ""}
                            value={
                              Role.find(
                                (industry) => industry.val === selectedFunction
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              setSelectedFunction(newValue ? newValue.val : "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Job Function"
                                variant="outlined"
                                fullWidth
                                sx={{
                                  backgroundColor: "#f7f8fa",
                                  borderRadius: "10px",
                                  "& .MuiInputLabel-root": {
                                    color: "#555",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "& input": {
                                      textAlign: "start", // Center align the input text
                                    },
                                    "& fieldset": {
                                      borderColor: "#d1d5db", // Custom border color
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#a0aec0", // Hover color
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#3f51b5", // Focused color
                                    },
                                  },
                                }}
                              />
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.val === value.val
                            }
                            noOptionsText="- - No options - -"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 400,
                              fontSize: "18px",
                              color: "#555",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            Current Job Title:
                          </Typography>
                          <Autocomplete
                            options={
                              Cjobtitles.filter((jobTitle) =>
                                jobTitle.val
                                  .toLowerCase()
                                  .includes(selectedFunction.toLowerCase())
                              ).length > 0
                                ? Cjobtitles.filter((jobTitle) =>
                                    jobTitle.val
                                      .toLowerCase()
                                      .includes(selectedFunction.toLowerCase())
                                  ) // Filtered job titles based on selected function
                                : Cjobtitles // Show all job titles if no filter matches
                            }
                            getOptionLabel={(option) => option.val || ""}
                            value={
                              Cjobtitles.find(
                                (industry) => industry.val === currentjob
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              setCurrentjob(newValue ? newValue.val : "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Job Title"
                                variant="outlined"
                                fullWidth
                                sx={{
                                  backgroundColor: "#f7f8fa",
                                  borderRadius: "10px",
                                  "& .MuiInputLabel-root": {
                                    color: "#555",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "& input": {
                                      textAlign: "start", // Center align the input text
                                    },
                                    "& fieldset": {
                                      borderColor: "#d1d5db", // Custom border color
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#a0aec0", // Hover color
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#3f51b5", // Focused color
                                    },
                                  },
                                }}
                              />
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.val === value.val
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <h4
                            style={{
                              fontWeight: 400,
                              color: "#555",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            Seniority Level:
                          </h4>
                          <FormControl fullWidth>
                            <Select
                              labelId="position-level-label"
                              multiple
                              value={positionLevelArray} // Ensure value is always an array
                              onChange={(e) => handleChange(e, "positionLevel")} // Corrected: use only event and field
                              renderValue={
                                (selected) =>
                                  selected
                                    .map((level) => level.level)
                                    .join(", ") // Display selected levels as comma-separated
                              }
                              variant="outlined"
                              sx={{
                                backgroundColor: "#f7f8fa",
                                borderRadius: "5px",
                              }}
                            >
                              {POS_Level.map((level) => (
                                <MenuItem key={level.id} value={level}>
                                  {" "}
                                  {/* Use the full object as the value */}
                                  <Checkbox
                                    checked={positionLevelArray.some(
                                      (item) => item.id === level.id
                                    )}
                                  />
                                  <ListItemText primary={level.level} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 400,
                              fontSize: "18px",
                              color: "#555",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            Company Type:
                          </Typography>
                          <Autocomplete
                            options={companyTypeOptions}
                            getOptionLabel={(option) => option.val || ""}
                            value={
                              companyTypeOptions.find(
                                (type) => type.val === companyType
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              setCompanyType(newValue ? newValue.val : "");
                              setCompanyTypeInput(newValue ? newValue.val : ""); // Update state for outside input
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Company Type"
                                variant="outlined"
                                fullWidth
                                sx={{
                                  backgroundColor: "#f7f8fa",
                                  borderRadius: "10px",
                                  "& .MuiInputLabel-root": {
                                    color: "#555",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "& input": {
                                      textAlign: "start", // Center align the input text
                                    },
                                    "& fieldset": {
                                      borderColor: "#d1d5db", // Custom border color
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#a0aec0", // Hover color
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#3f51b5", // Focused color
                                    },
                                  },
                                }}
                              />
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.val === value.val
                            }
                            noOptionsText="- - No options - -"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h4
                            style={{
                              fontWeight: 400,
                              color: "#555",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            Leads Required
                          </h4>
                          <TextField
                            type="text" // Changed from "number" to "text" to remove spinner arrows
                            placeholder="Enter Leads Required"
                            fullWidth
                            required
                            value={leadsRequired}
                            onChange={(e) => {
                              // Only allow numeric input
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                // This regex allows only digits (numbers)
                                setLeadsRequired(value);
                              }
                            }}
                            sx={{
                              backgroundColor: "#f7f8fa",
                              borderRadius: "5px",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
                {/* Submit Button */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center", // Centers the button horizontally
                    marginTop: 6,
                    width: "100%", // Full width of the container
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "350px", // Set the button width to 350px
                      padding: "12px 48px",
                      fontWeight: 600,
                      textTransform: "none",
                      backgroundImage:
                        "linear-gradient(to left, #5C76B2, #3561C7)",
                      "&:hover": {
                        backgroundImage:
                          "linear-gradient(to left, #2A3BD2, #216AF9)",
                      },
                    }}
                    onClick={handleCreateCampaign}
                  >
                    Create Campaign
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
export default Createcampaign;
