import React, { useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import * as pdfjsLib from "pdfjs-dist/webpack";
import Generatedcontent from "./Generatedcontent";
import JSZip from "jszip";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
  FormControl,
  FormHelperText,
  Box,
} from "@mui/material";
import Sidebar from "./sidebar";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";

const PromptGenerator = () => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [text, setText] = useState("");
  const [text1, setText1] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [text2, setText2] = useState("");
  const [prompt, setPrompt] = useState(""); // For storing the entered prompt
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [textError, setTextError] = useState(false);
  const [emailBody, setEmailBody] = useState("");
  const [subjectLine, setSubjectLine] = useState("");
  const [tone, setTone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [callToAction, setCallToAction] = useState("");
  const [selectError, setSelectError] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [aboutCompany, setAboutCompany] = useState("");
  const [servicesProvided, setServicesProvided] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [id, setId] = useState();
  const [emailBodyError, setEmailBodyError] = useState(false);
  const [subjectLineError, setSubjectLineError] = useState(false);
  const token = localStorage.getItem("access_token");
  const [toneError, setToneError] = useState(false);
  const templates = [
    { id: 1, name: "Template 1" },
    { id: 2, name: "Template 2" },
    { id: 3, name: "Template 3" },
    { id: 4, name: "Template 4" },
    { id: 5, name: "Template 5" },
  ];
  const [callToActionError, setCallToActionError] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false); // Initial state for sidebar
  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const location = useLocation();

  const { campaign_id, campaign_name } = location.state || {};

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation: Check if any required field is empty
    if (!selectValue || !emailBody || !subjectLine || !tone || !callToAction) {
      if (!selectValue) setSelectError(true);
      if (!emailBody) setEmailBodyError(true);
      if (!subjectLine) setSubjectLineError(true);
      if (!tone) setToneError(true);
      if (!callToAction) setCallToActionError(true);
      return; // Stop execution if validation fails
    }

    if (campaign_id) {
      const formData = new FormData();
      formData.append("campaign_id", campaign_id);
      formData.append("email_body", emailBody);
      formData.append("subject_line", subjectLine);
      formData.append("tone", tone);
      formData.append("call_to_action", callToAction);
      formData.append("about_content", "about company "); // Replace with actual 'about' content
      formData.append("services_content", "about this company ");
      formData.append("prompt", prompt);
      setIsLoading(true);

      try {
        const response = await axios.post(
          "https://plugleads.in/api/campaign-content/create-content/",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the Bearer token
            },
          }
        );

        if (response.status === 200) {
          setIsSubmitted(true);
          setFile1(null);
          setFile2(null);
          setText("");
          setUploadedImage(null);
          setData(response.data); // Update the state with the response
        } else {
          console.error(
            `Failed to create content. Status code: ${response.status}`
          );
          console.error("Response:", response.data);
        }
      } catch (error) {
        // Check for invalid token
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === "token_not_valid"
        ) {
          console.error("Token is invalid or expired. Logging out...");
          // Clear token and redirect to login page
          localStorage.removeItem("token"); // or sessionStorage.removeItem("token")
          window.location.href = "/signin"; // Replace with your login page path
        } else {
          console.error("Error posting data:", error);
        }
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      console.error("Campaign ID is missing.");
    }
  };

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
    setSelectError(event.target.value === "");
  };
  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <Grid>
        <Grid
          container
          sx={{
            margin: 0,
            padding: 0,
            width:
              sidebarOpen && isSubmitted
                ? { xs: "0", sm: "70%" }
                : { xs: "100%", sm: "80%" },
            ml: sidebarOpen
              ? { xs: "0", sm: "290px" }
              : { xs: "0", sm: "150px" },
            height: "100vh", // Full viewport height
            backgroundColor: isSubmitted ? "#FFF" : "#FFF",
          }}
        >
          {/* Left Section: Image */}
          {!isSubmitted && (
            <Grid
              item
              xs={12}
              md={5} // 60% width for larger screens
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                backgroundColor: "#FFF", // Add a background color or remove
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h2>Select an Option</h2>
                <div>
                  <button
                    onClick={() => handleOptionChange("option1")}
                    style={{
                      margin: "5px",
                      padding: "10px",
                      cursor: "pointer",
                      width: "80%",
                      backgroundColor:
                        selectedOption === "option1" ? "#007bff" : "#ccc",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Select Email Content Template
                  </button>
                  <button
                    onClick={() => handleOptionChange("option2")}
                    style={{
                      margin: "5px",
                      width: "80%",
                      marginBottom: "0px",
                      padding: "10px",
                      cursor: "pointer",
                      backgroundColor:
                        selectedOption === "option2" ? "#007bff" : "#ccc",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Generate Email Content
                  </button>
                </div>
              </Box>
            </Grid>
          )}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {/* Right Section: Form if Option 2 is selected  */}
          {selectedOption === "option2" && (
            <Grid
              item
              xs={12}
              md={7} // 40% width for larger screens
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                padding: "30px",
              }}
            >
              {!isSubmitted && (
                <form
                  onSubmit={handleSubmit}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "30px",
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      alignSelf: "center",
                      fontWeight: 600,
                      color: "#3f51b5",
                      marginBottom: "20px",
                    }}
                  >
                    Generate Mail Content
                  </Typography>

                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      alignSelf: "center",
                      fontWeight: 600,
                      color: "#E78128",
                      marginBottom: "20px",
                    }}
                  >
                    {campaign_name}
                  </Typography>

                  {/* Select Input Field */}
                  <label
                    htmlFor="inputcontent"
                    style={{
                      fontWeight: "normal",
                      marginBottom: "-22px",
                      fontSize: "14px",
                    }}
                  >
                    Please Select The Content Type To Your Campaign
                  </label>
                  <FormControl
                    id="inputcontent"
                    fullWidth
                    variant="outlined"
                    required
                    sx={{ marginBottom: "20px" }}
                    error={selectError}
                  >
                    <Select value={selectValue} onChange={handleSelectChange}>
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Text">Text</MenuItem>
                      <MenuItem disabled value="Image/Pictorial">
                        Image/Pictorial
                      </MenuItem>
                      <MenuItem disabled value="Text+Image">
                        Text+Image
                      </MenuItem>
                    </Select>
                    {selectError && (
                      <FormHelperText>Please select an option.</FormHelperText>
                    )}
                  </FormControl>

                  <label
                    style={{
                      padding: "0px",
                      margin: "0px",
                      marginBottom: "-23px",
                      fontWeight: "normal",
                    }}
                    htmlFor="prompt"
                  >
                    Enter Your Prompt
                  </label>

                  <TextField
                    id="prompt"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    required
                  />

                  {/* Email Body */}
                  <label
                    htmlFor="emailbody"
                    style={{
                      fontWeight: "normal",
                      marginBottom: "0px",
                      padding: "0px",
                      marginBottom: "-26px",
                      fontSize: "14px",
                    }}
                  >
                    Select Type Of The Email
                  </label>
                  <FormControl
                    fullWidth
                    margin="normal"
                    id="emailbody"
                    error={emailBodyError}
                  >
                    <Select
                      value={emailBody}
                      onChange={(e) => {
                        setEmailBody(e.target.value); // Update state
                        setEmailBodyError(""); // Clear the error
                      }}
                    >
                      <MenuItem value="Informational">Informational</MenuItem>
                      <MenuItem value="Promotional">Promotional</MenuItem>
                      <MenuItem value="Engagement-Focused">
                        Engagement-Focused
                      </MenuItem>
                      <MenuItem value="Storytelling">Storytelling</MenuItem>
                      <MenuItem value="Event Invitation">
                        Event Invitation
                      </MenuItem>
                      <MenuItem value="Educational">Educational</MenuItem>
                      <MenuItem value="Re-engagement">Re-engagement</MenuItem>
                    </Select>
                    {emailBodyError && (
                      <FormHelperText>
                        Select Atleast One Option.
                      </FormHelperText>
                    )}
                  </FormControl>

                  {/* Subject Line */}
                  <label
                    htmlFor="subjectline"
                    style={{
                      fontWeight: "normal",
                      marginBottom: "0px",
                      padding: "0px",
                      marginBottom: "-26px",
                      fontSize: "14px",
                    }}
                  >
                    Select Type Of Subject Line For Email
                  </label>
                  <FormControl
                    fullWidth
                    margin="normal"
                    id="subjectline"
                    error={subjectLineError}
                  >
                    <Select
                      value={subjectLine}
                      onChange={(e) => {
                        setSubjectLine(e.target.value); // Update state
                        setSubjectLineError(""); // Clear the error
                      }}
                    >
                      <MenuItem value="Emotional Appeal">
                        Emotional Appeal
                      </MenuItem>
                      <MenuItem value="Personalization">
                        Personalization
                      </MenuItem>
                      <MenuItem value="Value Proposition">
                        Value Proposition
                      </MenuItem>
                      <MenuItem value="Humor and Creativity">
                        Humor and Creativity
                      </MenuItem>
                      <MenuItem value="Informative">Informative</MenuItem>
                    </Select>
                    {subjectLineError && (
                      <FormHelperText>
                        Select Atleast One Option.
                      </FormHelperText>
                    )}
                  </FormControl>

                  {/* Tone */}
                  <label
                    htmlFor="tone"
                    style={{
                      fontWeight: "normal",
                      marginBottom: "0px",
                      padding: "0px",
                      marginBottom: "-26px",
                      fontSize: "14px",
                    }}
                  >
                    Select Tone Of Your Email
                  </label>
                  <FormControl
                    fullWidth
                    margin="normal"
                    id="tone"
                    error={toneError}
                  >
                    <Select
                      value={tone}
                      onChange={(e) => {
                        setTone(e.target.value); // Update state
                        setToneError(""); // Clear the error
                      }}
                    >
                      <MenuItem value="Formal">Formal</MenuItem>
                      <MenuItem value="Conversational">Conversational</MenuItem>
                      <MenuItem value="Inspirational">Inspirational</MenuItem>
                      <MenuItem value="Minimalistic">Minimalistic</MenuItem>
                    </Select>
                    {toneError && (
                      <FormHelperText>
                        Select Atleast One Option.
                      </FormHelperText>
                    )}
                  </FormControl>

                  {/* Call-to-Actions */}
                  <label
                    htmlFor="action"
                    style={{
                      fontWeight: "normal",
                      marginBottom: "0px",
                      padding: "0px",
                      marginBottom: "-26px",
                      fontSize: "14px",
                    }}
                  >
                    Select Call To Action In Your Email
                  </label>
                  <FormControl
                    fullWidth
                    margin="normal"
                    id="action"
                    error={callToActionError}
                  >
                    <Select
                      value={callToAction}
                      onChange={(e) => {
                        setCallToAction(e.target.value); // Update state
                        setCallToActionError(""); // Clear the error
                      }}
                    >
                      <MenuItem value="Action-Oriented">
                        Action-Oriented
                      </MenuItem>
                      <MenuItem value="Benefit-Oriented">
                        Benefit-Oriented
                      </MenuItem>
                      <MenuItem value="Time-Sensitive">Time-Sensitive</MenuItem>
                    </Select>
                    {callToActionError && (
                      <FormHelperText>
                        Select Atleast One Option.
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                    sx={{
                      width: "30%",
                      padding: "12px",
                      alignSelf: "start",
                      fontSize: "16px",
                      borderRadius: "8px",
                      textTransform: "none",
                      backgroundColor: "#3f51b5",
                      "&:hover": {
                        backgroundColor: "#303f9f",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </form>
              )}
            </Grid>
          )}
          {/* Right Section: Form if Option 1 is selected  */}
          {selectedOption === "option1" && (
            <Grid
              item
              xs={12}
              md={7} // 40% width for larger screens
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                backgroundColor: "white",
                padding: "30px",
              }}
            >
              {templates.map((template) => (
                <Box
                  key={template.id}
                  sx={{
                    width: "100px",
                    height: "100px",
                    backgroundColor: "#f5f5f5",
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                    fontSize: "12px",
                    alignItems: "center",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    cursor: "pointer",
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "scale(1.1)",
                      backgroundColor: "#e0e0e0",
                    },
                  }}
                >
                  {template.name}
                </Box>
              ))}
            </Grid>
          )}

          {isSubmitted && (
            <Generatedcontent
              data={data}
              id={campaign_id}
              prompt={prompt}
              emailBody={emailBody}
              subjectLine={subjectLine}
              tone={tone}
              callToAction={callToAction}
              sx={{ width: "80vw" }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PromptGenerator;
